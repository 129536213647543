import { useAuth0 } from "@auth0/auth0-react";
import AuthService from "../../models/AuthService";
import User from "../../models/User";
import { UserRole } from "@/shared/enums/UserRole";

const namespace = import.meta.env.VITE_AUTH0_AUDIENCE as string;

export const useAuth0Service = (): AuthService => {
  const {
    isAuthenticated,
    isLoading,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const logoutWithRedirect = async (url: string) => {
    await logout({ logoutParams: { returnTo: url } });
  };

  const hasRole = (role: UserRole) => {
    if (!user) return false;
    const roles = user[`${namespace}roles`] || [];
    return roles.includes(role);
  };

  return {
    isAuthenticated,
    isLoading,
    user: user as User | null,
    loginWithRedirect,
    logout,
    logoutWithRedirect,
    getAccessToken: getAccessTokenSilently,
    hasRole,
  };
};
