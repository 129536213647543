import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetChat } from "../features/existing-chat/api/useGetChat";
import { Card } from "@/components/ui/card";
import ExistingChat from "../features/existing-chat/ExistingChat";
import { useLocalStorage } from "@mantine/hooks";
import { LocalStorageKeys } from "../shared/enums/LocalStorageKeys";
import { FileDown } from "lucide-react";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { BackToStartButton } from "@/components/ui/back-to-start-button";
import { ResearchContext } from "@/components/research-context/ResearchContext";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ChevronDown } from "lucide-react";
import { CitationOverview } from "../features/existing-chat/components/CitationOverview";
import { Citation, CitationIndex } from "../features/sources/models/Citation";
import { CitationSourceType } from "../shared/enums/CitationSourceType";
import { useSendUserInputV2 } from "../features/existing-chat/api/useSendUserInputV2";
import { AddContextModal } from "../features/chat-management/components/AddContextModal";
import { useExportChat } from "@/features/existing-chat/api/useExportChat";
import SourcesOverview from "@/features/existing-chat/components/SourcesOverview";
import { ChatReportFormat } from "@/shared/enums/ChatReportFormat";
import ChatContext from "@/shared/models/ChatContext";

export default function Chat() {
  const { chatId } = useParams();
  const { chat, getChat } = useGetChat(chatId || "");
  const [chatTitle, setChatTitle] = useState<string>("");
  const [showAddContext, setShowAddContext] = useState(false);
  const [selectedCitation, setSelectedCitation] =
    useState<CitationIndex | null>(null);
  const [sidebarCitation] = useLocalStorage<string | undefined>({
    key: LocalStorageKeys.SidebarCitation,
    defaultValue: undefined,
  });
  const { sendUserInputV2 } = useSendUserInputV2(chatId || "");
  const { isExporting, exportChat } = useExportChat();
  const [messageCitations, setMessageCitations] = useState<Citation[] | null>();
  const [savedReportFormats] = useLocalStorage<
    Record<string, ChatReportFormat>
  >({
    key: LocalStorageKeys.ReportFormats,
    defaultValue: {},
  });
  const [reportFormat, setReportFormat] = useState(ChatReportFormat.Detailed);

  useEffect(() => {
    if (chatId && savedReportFormats[chatId]) {
      setReportFormat(savedReportFormats[chatId]);
    }
  }, [chatId, savedReportFormats]);

  useEffect(() => {
    if (chatId && chatId !== "new") {
      getChat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  useEffect(() => {
    if (chatId === "new") {
      setChatTitle("New Research");
    } else if (chat?.input) {
      setChatTitle(chat.input);
    }
  }, [chatId, chat]);

  useEffect(() => {
    if (sidebarCitation) {
      try {
        const citation = JSON.parse(sidebarCitation);
        setSelectedCitation(citation);
      } catch (error) {
        console.error("Error parsing citation:", error);
        setSelectedCitation(null);
      }
    } else {
      setSelectedCitation(null);
    }
  }, [sidebarCitation]);

  const handleContextComplete = async (contextData: ChatContext) => {
    const data = contextData.extractChatCreateData();

    try {
      await sendUserInputV2(
        "Update context",
        data.companyIds,
        data.filingIds,
        data.transcriptIds,
        data.files,
        data.urls,
      );
      setShowAddContext(false);
      getChat(); // Refresh chat data
    } catch (error) {
      console.error("Error updating context:", error);
    }
  };

  if (!chatId) {
    return null;
  }

  return (
    <div className="min-h-screen bg-background flex">
      <div
        className={cn(
          "flex-grow flex flex-col min-h-screen",
          selectedCitation || messageCitations
            ? selectedCitation?.sourceType === CitationSourceType.Estimates ||
              selectedCitation?.sourceType === CitationSourceType.UserContent
              ? "max-w-[60%] px-4"
              : "max-w-[70%] px-4"
            : "container mx-auto transition-[margin] duration-300 ease-in-out delay-[200ms]",
        )}
      >
        <div
          className={cn(
            "w-full h-full flex flex-col transition-[max-width] duration-200 ease-in-out",
            selectedCitation || messageCitations ? "" : "max-w-full",
          )}
        >
          {/* Sticky Header */}
          <div className="sticky top-0 z-10 bg-background pt-6 pb-4">
            <BackToStartButton />

            {/* Header Content */}
            <div className="flex items-center justify-between mt-6 mb-4">
              <h1 className="text-2xl font-semibold text-gray-900 max-w-[80%] line-clamp-2">
                {chatTitle}
              </h1>
              <div className="flex gap-4">
                <Button
                  size="lg"
                  className="bg-gray-900 hover:bg-gray-800 text-white shadow-md hover:shadow-lg
                         transition-all duration-200 gap-2 h-11"
                  onClick={() => exportChat(chatId)}
                  disabled={isExporting}
                >
                  {isExporting ? (
                    <>
                      <div className="h-4 w-4 animate-spin rounded-full border-2 border-current border-t-transparent mr-2" />
                      Exporting...
                    </>
                  ) : (
                    <>
                      <FileDown className="h-5 w-5" />
                      Download
                    </>
                  )}
                </Button>
                <div className="flex">
                  <Button
                    size="lg"
                    className="bg-gray-900 hover:bg-gray-800 text-white shadow-md hover:shadow-lg
               transition-all duration-200 gap-2 h-11 rounded-r-none"
                    onClick={() =>
                      window.open(
                        `/c/${chatId}/report?format=${reportFormat}`,
                        "_blank",
                        "noopener,noreferrer",
                      )
                    }
                  >
                    <FileDown className="h-5 w-5" />
                    Convert to Report
                  </Button>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        size="lg"
                        className="bg-gray-900 hover:bg-gray-800 text-white shadow-md hover:shadow-lg
                 transition-all duration-200 h-11 rounded-l-none border-l border-gray-700 px-2"
                      >
                        <ChevronDown className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      className="w-40 bg-gray-900 text-white"
                      onCloseAutoFocus={(e) => e.preventDefault()}
                    >
                      <DropdownMenuRadioGroup
                        value={reportFormat}
                        onValueChange={(value) =>
                          setReportFormat(value as ChatReportFormat)
                        }
                      >
                        <DropdownMenuRadioItem
                          value={ChatReportFormat.Detailed}
                        >
                          {ChatReportFormat.Detailed}
                        </DropdownMenuRadioItem>
                        <DropdownMenuRadioItem
                          value={ChatReportFormat.ShortSummary}
                        >
                          {ChatReportFormat.ShortSummary}
                        </DropdownMenuRadioItem>
                        <DropdownMenuRadioItem
                          value={ChatReportFormat.BulletPoints}
                        >
                          {ChatReportFormat.BulletPoints}
                        </DropdownMenuRadioItem>
                      </DropdownMenuRadioGroup>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
            </div>

            {/* Research Context */}
            <ResearchContext
              metadata={chat?.metadata}
              onAddContext={() => setShowAddContext(true)}
            />
          </div>

          {/* Main Content */}
          <div className="flex-1 flex flex-col">
            <Card className="flex-1 flex flex-col overflow-hidden">
              <ExistingChat
                chatId={chatId}
                onCitationClick={(citation) => {
                  setSelectedCitation(citation);
                  // Clear message citations to not overlap
                  if (messageCitations) {
                    setMessageCitations(null);
                  }
                }}
                onSourcesClick={(citations) => {
                  setMessageCitations(citations);
                  // Clear citation to not overlap
                  if (selectedCitation) {
                    setSelectedCitation(null);
                  }
                }}
              />
            </Card>
          </div>
        </div>
      </div>

      {/* Add Context Modal */}
      <AddContextModal
        open={showAddContext}
        onClose={() => setShowAddContext(false)}
        onComplete={handleContextComplete}
        initialNews={chat?.metadata?.useNews || false}
        initialWeb={chat?.metadata?.useInternet || false}
        isExistingChat={true}
      />

      {/* Citation Panel */}
      <div
        className={cn(
          "fixed right-0 top-0 h-screen bg-background border-l transition-transform duration-200 ease-in-out overflow-hidden",
          selectedCitation || messageCitations
            ? "translate-x-0"
            : "translate-x-full w-0",
          selectedCitation?.sourceType === CitationSourceType.Estimates ||
            selectedCitation?.sourceType === CitationSourceType.UserContent
            ? "w-[40%]"
            : "w-[30%]",
        )}
      >
        {(selectedCitation || messageCitations) && (
          <div className="h-full m-5">
            <div className="h-full">
              {selectedCitation && (
                <CitationOverview
                  citation={selectedCitation}
                  close={() => setSelectedCitation(null)}
                  chatId={chatId}
                  userContents={chat?.userContent || []}
                />
              )}
              {messageCitations && (
                <SourcesOverview
                  citations={messageCitations}
                  close={() => setMessageCitations(null)}
                  chatId={chatId}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
