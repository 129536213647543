import ChatContext from "@/shared/models/ChatContext";
import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import {
  EventName,
  EventParameter,
  sendEvent,
} from "../../../shared/utils/UsageAnalytics";

export const useEstimatePlaybookChat = () => {
  const {
    data: cost,
    call,
    isLoading,
    isFailed,
  } = useApiPrivateRequest<number>((response) => parseFloat(response));
  const estimate = async (
    playbookId: string,
    isDefaultPlaybook: boolean,
    context: ChatContext,
  ) => {
    const data = context.extractChatCreateData();

    const formatData = new FormData();
    formatData.append("PlaybookId", playbookId);
    formatData.append("IsDefaultPlaybook", isDefaultPlaybook.toString());
    data.companyIds.forEach((id) =>
      formatData.append("CompanyIds", id.toString()),
    );
    data.filingIds.forEach((id) => formatData.append("FilingIds", id));
    data.transcriptIds.forEach((id) => formatData.append("TranscriptIds", id));
    data.urls.forEach((url) => formatData.append("Urls", url));
    data.files.forEach((file) => formatData.append("Files", file));
    formatData.append("UseNews", data.includeNews.toString());
    formatData.append("UseInternet", data.includeWeb.toString());

    await call("/playbook/estimate", "POST", {}, formatData);

    sendEvent(EventName.PlaybookEstimate, {
      [EventParameter.PlaybookId]: playbookId,
    });
  };

  return { cost, estimate, isLoading, isFailed };
};
