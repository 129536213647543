import * as signalR from "@microsoft/signalr";
import { useEffect } from "react";
import { logger } from "../utils/Logger";
import { useAuth } from "../auth/useAuth";

const PING_TIMEOUT = 5000;
const PING_ERROR_MESSAGE = "Ping timeout";
const CONNECTION_TIMEOUT = 60000;
const KEEP_ALIVE_INTERVAL = 10000;
const MAX_RECONNECT_ATTEMPTS = 10;
const CONNECTION_CHECK_INTERVAL = 15000;

interface SignalRConfig {
  hubUrl: string;
  channelName: string;
  logPrefix?: string;
  handleMessage: (name: string, data: string) => void;
  dependencies?: any[];
}

export const useSignalRConnection = ({
  hubUrl,
  channelName,
  logPrefix = "",
  handleMessage,
  dependencies = [],
}: SignalRConfig): void => {
  const { getAccessToken } = useAuth();

  useEffect(() => {
    let reconnectAttempts = 0;
    let currentConnection: signalR.HubConnection | null = null;

    // Create a new hub connection with the specified transport options
    const createConnection = (transportType?: signalR.HttpTransportType) => {
      const options: signalR.IHttpConnectionOptions = {
        withCredentials: false,
        accessTokenFactory: getAccessToken,
        skipNegotiation: false,
        headers: {
          "X-Requested-With": "XMLHttpRequest", // May help with Cloudflare/DO blocking connections
        },
      };

      // If transport is specified, use only that transport
      if (transportType) {
        options.transport = transportType;
      } else {
        options.transport =
          signalR.HttpTransportType.WebSockets |
          signalR.HttpTransportType.ServerSentEvents |
          signalR.HttpTransportType.LongPolling;
      }

      return new signalR.HubConnectionBuilder()
        .withUrl(`${import.meta.env.VITE_API_BASE_URL}${hubUrl}`, options)
        .withAutomaticReconnect([
          0, 2000, 5000, 10000, 20000, 30000, 45000, 60000,
        ])
        .configureLogging(signalR.LogLevel.Information)
        .build();
    };

    // Configure connection and register event handlers
    const configureConnection = (connection: signalR.HubConnection) => {
      connection.serverTimeoutInMilliseconds = CONNECTION_TIMEOUT;
      connection.keepAliveIntervalInMilliseconds = KEEP_ALIVE_INTERVAL;

      // Register both camelCase and PascalCase versions to be safe
      connection.on("ReceiveMessage", handleMessage);
      connection.on("receivemessage", handleMessage);

      connection.onreconnecting((error) => {
        logger.warn(`SignalR${logPrefix} reconnecting due to error: `, error);
      });

      connection.onreconnected((connectionId) => {
        logger.debug(
          `SignalR${logPrefix} reconnected with ID: ${connectionId}`,
        );
        // Re-join group after reconnection
        connection.invoke("JoinGroup", channelName).catch((error) => {
          logger.error(
            `SignalR${logPrefix} failed to rejoin group after reconnection: `,
            error,
          );
        });
      });

      connection.onclose((error) => {
        logger.debug(`SignalR${logPrefix} connection closed. Error: `, error);
        // Only increment reconnect attempts on actual close, not on manual stops
        if (error) {
          reconnectAttempts++;
          const backoffTime = Math.min(
            1000 * Math.pow(2, reconnectAttempts),
            30000,
          );
          setTimeout(() => startConnection(), backoffTime);
        }
      });

      return connection;
    };

    // Start a connection with the provided transport type
    const startConnection = async (
      transportType?: signalR.HttpTransportType,
    ) => {
      try {
        // Clean up existing connection if it exists
        if (currentConnection) {
          try {
            currentConnection.off("ReceiveMessage", handleMessage);
            currentConnection.off("receivemessage", handleMessage);
            if (
              currentConnection.state === signalR.HubConnectionState.Connected
            ) {
              await currentConnection.stop();
              logger.debug(`SignalR${logPrefix} stopped previous connection`);
            }
          } catch (error) {
            logger.error(
              `SignalR${logPrefix} error stopping previous connection: `,
              error,
            );
          }
        }

        // Create a new connection with the specified transport
        const connection = createConnection(transportType);
        currentConnection = configureConnection(connection);

        // Check if we've exceeded max reconnect attempts
        if (reconnectAttempts >= MAX_RECONNECT_ATTEMPTS && !transportType) {
          logger.warn(
            `SignalR${logPrefix} maximum reconnect attempts reached. Using fallback transport.`,
          );
          return startConnection(signalR.HttpTransportType.LongPolling);
        }

        if (
          currentConnection.state === signalR.HubConnectionState.Disconnected
        ) {
          await currentConnection.start();
          reconnectAttempts = 0; // Reset counter on successful connection

          const transportName =
            transportType === signalR.HttpTransportType.LongPolling
              ? "LongPolling"
              : "default";

          logger.debug(`SignalR${logPrefix} connected using ${transportName}`);
          await currentConnection.invoke("JoinGroup", channelName);
          logger.debug(`SignalR${logPrefix} joined group: ${channelName}`);
        }
      } catch (error) {
        reconnectAttempts++;
        logger.error(
          `SignalR${logPrefix} connection error (attempt ${reconnectAttempts}): `,
          error,
        );
        // Try again with exponential backoff
        const backoffTime = Math.min(
          1000 * Math.pow(2, reconnectAttempts),
          30000,
        );
        setTimeout(() => startConnection(transportType), backoffTime);
      }
    };

    // Ping the server to check connection health
    const pingWithTimeout = async (timeoutMs: number) => {
      if (!currentConnection) return;

      try {
        return await Promise.race([
          currentConnection.invoke("Ping"),
          new Promise((_, reject) =>
            setTimeout(() => reject(new Error(PING_ERROR_MESSAGE)), timeoutMs),
          ),
        ]);
      } catch (error) {
        logger.warn(`SignalR${logPrefix} ping failed: `, error);
        throw error;
      }
    };

    // Periodically check connection health
    const checkConnection = async () => {
      if (!currentConnection) return;

      if (currentConnection.state === signalR.HubConnectionState.Connected) {
        try {
          await pingWithTimeout(PING_TIMEOUT);
          logger.debug(`SignalR${logPrefix} ping successful`);
        } catch (error) {
          if (error instanceof Error) {
            logger.warn(`SignalR${logPrefix} ping error: ${error.message}`);
            if (error.message === PING_ERROR_MESSAGE) {
              // Only restart connection on ping timeout, not other errors
              logger.debug(
                `SignalR${logPrefix} restarting connection due to ping timeout`,
              );
              await startConnection(); // Restart with default transport options
            }
          }
        }
      } else if (
        currentConnection.state === signalR.HubConnectionState.Disconnected
      ) {
        await startConnection();
      }
    };

    startConnection();

    // Set up connection health check on interval
    const intervalId = setInterval(checkConnection, CONNECTION_CHECK_INTERVAL);

    return () => {
      clearInterval(intervalId);

      if (currentConnection) {
        currentConnection.off("ReceiveMessage", handleMessage);
        currentConnection.off("receivemessage", handleMessage);

        if (currentConnection.state === signalR.HubConnectionState.Connected) {
          currentConnection
            .invoke("LeaveGroup", channelName)
            .then(() => {
              logger.debug(`SignalR${logPrefix} left group: ${channelName}`);
            })
            .catch((error) => {
              logger.error(`Failed to leave group${logPrefix}: `, error);
            })
            .finally(() => {
              currentConnection?.stop();
              logger.debug(`SignalR${logPrefix} connection stopped`);
            });
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);
};
