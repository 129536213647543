import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { NewsEntry } from "../models/NewsEntry";

export const useGetMultipleNews = () => {
  const {
    data: news,
    call,
    isLoading,
    isFailed,
  } = useApiPrivateRequest<NewsEntry[]>((response) =>
    response.map((document: any) => new NewsEntry(document)),
  );
  const getNews = async (newsIds: string[]) => {
    await call(`/marketData/news`, "GET", { ids: newsIds });
  };

  return { news, getNews, isLoading, isFailed };
};
