import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { Playbook, PlaybookStepShort } from "../model/Playbook";

export const useUpdatePlaybook = () => {
  const {
    data: updatedPlaybook,
    call,
    isLoading: isUpdating,
    isFailed: isUpdateFailed,
  } = useApiPrivateRequest<Playbook>((response) => new Playbook(response));

  const updatePlaybook = async (
    id: string,
    title?: string,
    description?: string,
    starred?: boolean,
    steps?: PlaybookStepShort[],
  ) => {
    const result = await call(`/playbook/${id}`, "PUT", undefined, {
      title,
      description,
      starred,
      steps,
    });
    return result;
  };

  return {
    updatedPlaybook,
    updatePlaybook,
    isUpdating,
    isUpdateFailed,
  };
};
