import { useApiPrivateRequest } from "../../../shared/hooks/useApi";

interface ImprovePromptInChatRequest {
  prompt: string;
  chatId: string;
  improvementDescription?: string;
}

export const useImprovePromptInChat = () => {
  const {
    data: improvedPrompt,
    call,
    isLoading,
  } = useApiPrivateRequest<string>((response) => response);

  const improvePromptInChat = async (
    prompt: string,
    chatId: string,
    improvementDescription?: string,
  ) => {
    const request: ImprovePromptInChatRequest = {
      prompt,
      chatId,
      improvementDescription,
    };
    return await call(
      "/generation/improvePromptInChat",
      "POST",
      undefined,
      request,
    );
  };

  return { improvedPrompt, improvePromptInChat, isLoading };
};
