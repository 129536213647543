import React, { ReactNode } from "react";

import { Auth0AuthProvider } from "./providers/auth0/Auth0AuthProvider";
import { KeycloakAuthProvider } from "./providers/keycloak/KeycloakAuthProvider";

interface RootAuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<RootAuthProviderProps> = ({ children }) => {
  const authProvider = import.meta.env.VITE_IDENTITY_PROVIDER || "auth0";

  switch (authProvider.toLowerCase()) {
    case "keycloak":
      return <KeycloakAuthProvider>{children}</KeycloakAuthProvider>;
    case "auth0":
      return <Auth0AuthProvider>{children}</Auth0AuthProvider>;
    default:
      return <Auth0AuthProvider>{children}</Auth0AuthProvider>;
  }
};
