import { Switch } from "@/components/ui/switch";
import { Newspaper } from "lucide-react";

interface UseNewsSearchToggleProps {
  includeNews: boolean;
  setIncludeNews: (value: boolean) => void;
  disabled?: boolean;
}

export function UseNewsSearchToggle({
  includeNews,
  setIncludeNews,
  disabled = false,
}: UseNewsSearchToggleProps) {
  return (
    <button
      onClick={() => setIncludeNews(!includeNews)}
      disabled={disabled}
      className={`w-full transition-colors 
              ${includeNews ? "bg-emerald-50 border-emerald-200" : "bg-gray-50 border-gray-200"} 
              border-2 rounded-lg p-4 hover:bg-emerald-50/70 group`}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div
            className={`p-2 rounded-lg ${includeNews ? "bg-emerald-100" : "bg-gray-100"}`}
          >
            <Newspaper
              className={`h-5 w-5 ${includeNews ? "text-emerald-600" : "text-gray-600"}`}
            />
          </div>
          <div className="text-left">
            <h3
              className={`font-medium ${includeNews ? "text-emerald-900" : "text-gray-900"}`}
            >
              Include News
            </h3>
            <p
              className={`text-sm ${includeNews ? "text-emerald-600" : "text-gray-600"}`}
            >
              News articles will be included
            </p>
          </div>
        </div>
        <Switch
          checked={includeNews}
          onCheckedChange={setIncludeNews}
          className={includeNews ? "bg-emerald-500" : ""}
          disabled={disabled}
        />
      </div>
    </button>
  );
}
