import { IconFileAnalytics } from "@tabler/icons-react";
import { useMemo } from "react";
import { generateDocumentLink } from "../../../../shared/utils/Strings";
import { CompanyDocumentView } from "../../../../shared/models/CompanyDocument";
import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../../shared/utils/ResponsiveStyles";

interface SidebarCompanyDocumentLinkProps {
  document: CompanyDocumentView;
  chunkId?: number;
  includeCompanyName?: boolean;
}

export default function SidebarCompanyDocumentLink({
  document,
  chunkId,
  includeCompanyName = true,
}: SidebarCompanyDocumentLinkProps) {
  const link = useMemo(() => {
    return generateDocumentLink(document.id, document.type, chunkId);
  }, [document, chunkId]);

  const title = includeCompanyName
    ? document.title
    : `${document.formType} ${document.period}`;

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="block w-full"
    >
      <div className="flex flex-col gap-1.5 w-full">
        <div className="flex items-center gap-2">
          <IconFileAnalytics
            className="text-primary"
            size={isLargeScreen ? 20 : 16}
          />
          <span className="flex-1 text-sm font-bold text-primary underline whitespace-normal break-words leading-relaxed normal-case">
            {title}
          </span>
        </div>
      </div>
    </a>
  );
}
