import { useApiPrivateRequest } from "@/shared/hooks/useApi";

export const useCreateApiToken = () => {
  const {
    data: token,
    setData: setToken,
    call,
    isLoading,
    isFailed: isCreateTokenFailed,
  } = useApiPrivateRequest<string>((response) => response);

  const createApiToken = async (userId: string) => {
    await call(`/admin/user/${userId}/apiToken`, "PUT");
  };
  return { token, createApiToken, isLoading, setToken, isCreateTokenFailed };
};
