import { Button } from "@/components/ui/button";
import { useGetCompanies } from "@/features/sources/api/useGetCompanies";
import { useGetFilings } from "@/features/sources/api/useGetFilings";
import { useGetTranscripts } from "@/features/sources/api/useGetTranscripts";
import { Citation } from "@/features/sources/models/Citation";
import CenterLoader from "@/shared/components/loader/CenterLoader";
import { CitationSourceType } from "@/shared/enums/CitationSourceType";
import { useMediaQuery } from "@mantine/hooks";
import { ScrollArea } from "@radix-ui/react-scroll-area";
import { IconComponents, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import SidebarCompanyDocumentLink from "./sources-sidebar/SidebarCompanyDocumentLink";
import { isLargeScreenMediaQuery } from "@/shared/utils/ResponsiveStyles";
import { useGetMultipleNews } from "@/features/sources/api/useGetMultipleNews";
import SidebarWebLink from "./sources-sidebar/SidebarWebLink";
import { useGetMultipleWebResult } from "@/features/sources/api/useGetMultipleWebResult";
import { extractDomain } from "@/shared/utils/Urls";
import SidebarUserContent from "./sources-sidebar/SidebarUserContent";

interface SourcesOverviewProps {
  chatId: string;
  citations: Citation[];
  close: () => void;
}

export default function SourcesOverview({
  chatId,
  citations,
  close,
}: SourcesOverviewProps) {
  const [isLoading, setIsLoading] = useState(true);
  const { transcripts, getTranscripts } = useGetTranscripts();
  const { filings, getFilings } = useGetFilings();
  const { news, getNews } = useGetMultipleNews();
  const { webResults, getWebResults } = useGetMultipleWebResult();
  const { companies, getCompanies } = useGetCompanies();
  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  const transcriptIds = citations
    .filter((citation) => citation.sourceType === CitationSourceType.Transcript)
    .map((citation) => parseInt(citation.documentId));
  const filingIds = citations
    .filter((citation) => citation.sourceType === CitationSourceType.Filing)
    .map((citation) => citation.documentId);
  const estimates = citations
    .filter((citation) => citation.sourceType === CitationSourceType.Estimates)
    .map((citation) => {
      return {
        companyId: parseInt(citation.documentId),
        period: citation.chunkId,
      };
    });
  const newsIds = citations
    .filter((citation) => citation.sourceType === CitationSourceType.News)
    .map((citation) => citation.documentId);
  const userContentIds = citations
    .filter(
      (citation) => citation.sourceType === CitationSourceType.UserContent,
    )
    .map((citation) => citation.documentId);
  const internetIds = citations
    .filter((citation) => citation.sourceType === CitationSourceType.Internet)
    .map((citation) => citation.documentId);

  useEffect(() => {
    if (transcriptIds.length > 0) {
      getTranscripts(transcriptIds);
    }

    if (filingIds.length > 0) {
      getFilings(filingIds);
    }

    if (estimates.length > 0) {
      getCompanies(estimates.map((e) => e.companyId));
    }

    if (newsIds.length > 0) {
      getNews(newsIds);
    }

    if (internetIds.length > 0) {
      getWebResults(chatId, internetIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      (transcriptIds.length === 0 || transcripts) &&
      (filingIds.length === 0 || filings) &&
      (estimates.length === 0 || companies) &&
      (newsIds.length === 0 || news) &&
      (internetIds.length === 0 || webResults)
    ) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcripts, filings, webResults, news, companies]);

  return (
    <div className="h-full flex flex-col">
      <div className="flex justify-end mb-2">
        <Button variant="ghost" size="icon" onClick={close}>
          <IconX className="h-4 w-4" />
        </Button>
      </div>
      {isLoading && <CenterLoader />}
      {!isLoading && (
        <ScrollArea className="flex-grow">
          {/* Transcripts */}
          {transcripts &&
            transcripts.length > 0 &&
            transcripts.map((t) => (
              <div className="mb-5">
                <SidebarCompanyDocumentLink document={t} />
              </div>
            ))}

          {/* Filings */}
          {filings &&
            filings.length > 0 &&
            filings.map((f) => (
              <div className="mb-5">
                <SidebarCompanyDocumentLink document={f} />
              </div>
            ))}

          {/* Estimates */}
          {companies &&
            companies.length > 0 &&
            companies.map((c) => (
              <div className="flex items-start gap-2 mb-5">
                <IconComponents
                  className="text-primary"
                  size={isLargeScreen ? 20 : 16}
                />
                <span className="flex-1 text-sm font-bold text-primary whitespace-normal break-words leading-relaxed normal-case">
                  {`${c.companyName} consensus estimates for ${[
                    ...new Set(
                      estimates
                        .filter((e) => e.companyId === c.id)
                        .map((e) => e.period),
                    ),
                  ].join(", ")}`}
                </span>
              </div>
            ))}

          {/* News */}
          {news &&
            news.length > 0 &&
            news.map((n) => (
              <div className="mb-5">
                <SidebarWebLink
                  title={n.title}
                  url={n.sourceUrl}
                  source={n.source}
                  sourceType={CitationSourceType.News}
                />
              </div>
            ))}

          {/* User Content */}
          {userContentIds.length > 0 &&
            [...new Set(userContentIds)].map((id) => (
              <div className="mb-5">
                <SidebarUserContent key={id} chatId={chatId} contentId={id} />
              </div>
            ))}

          {/* Web Results */}
          {webResults &&
            webResults.length > 0 &&
            webResults.map((w) => (
              <div className="mb-5">
                <SidebarWebLink
                  title={w.title}
                  url={w.url}
                  source={extractDomain(w.url)}
                  sourceType={CitationSourceType.Internet}
                />
              </div>
            ))}
        </ScrollArea>
      )}
    </div>
  );
}
