import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { Wand2 } from "lucide-react";
import { PlaybookDetailLevel } from "../models/PlaybookDetailLevel";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";

interface PlaybookGenerateModalProps {
  open: boolean;
  onClose: () => void;
  onGenerate: () => void;
  name: string;
  setName: (name: string) => void;
  purpose: string;
  setPurpose: (purpose: string) => void;
  detailLevel: PlaybookDetailLevel;
  setDetailLevel: (level: PlaybookDetailLevel) => void;
  isGenerating?: boolean;
}

export function PlaybookGenerateModal({
  open,
  onClose,
  onGenerate,
  isGenerating = false,
  name,
  setName,
  purpose,
  setPurpose,
  detailLevel,
  setDetailLevel,
}: PlaybookGenerateModalProps) {
  const isValid = name.trim() !== "" && purpose.trim() !== "";

  const handleGenerate = () => {
    if (isValid) {
      onGenerate();
    }
  };

  const handleDetailLevelChange = async (newValue: string) => {
    setDetailLevel(newValue as PlaybookDetailLevel);
  };

  return (
    <Dialog open={open} onOpenChange={() => onClose()}>
      <DialogContent className="max-w-lg">
        <DialogHeader>
          <div className="flex items-center gap-2">
            <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center">
              <Wand2 className="h-4 w-4 text-primary" />
            </div>
            <DialogTitle className="text-xl">
              Generate Playbook with AI
            </DialogTitle>
          </div>
        </DialogHeader>

        <div className="space-y-6 py-4">
          <div className="space-y-2">
            <Label htmlFor="name">Playbook Name</Label>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter a name for your playbook"
              disabled={isGenerating}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="purpose">Purpose Description</Label>
            <Textarea
              id="purpose"
              value={purpose}
              onChange={(e) => setPurpose(e.target.value)}
              placeholder="What do you want this playbook to help you achieve? Be specific to get better results."
              className="min-h-[120px] resize-none"
              disabled={isGenerating}
            />
            <p className="text-sm text-muted-foreground">
              Tip: Include specific goals, tasks, or outcomes you want to
              achieve
            </p>
          </div>

          <div className="space-y-2">
            <Label htmlFor="purpose">Playbook Detail:</Label>
            <Tabs
              defaultValue={detailLevel}
              className="w-[100%]"
              onValueChange={handleDetailLevelChange}
            >
              <TabsList className="grid w-full grid-cols-3">
                {Object.values(PlaybookDetailLevel).map((level) => (
                  <TabsTrigger key={level} value={level}>
                    {level}
                  </TabsTrigger>
                ))}
              </TabsList>
            </Tabs>
            {detailLevel === PlaybookDetailLevel.Concise && (
              <p className="text-sm text-muted-foreground">
                A quick, high-level playbook with 2-3 general steps for fast
                decision-making
              </p>
            )}
            {detailLevel === PlaybookDetailLevel.Normal && (
              <p className="text-sm text-muted-foreground">
                A balanced playbook with around 6 steps, providing clear and
                structured guidance
              </p>
            )}
            {detailLevel === PlaybookDetailLevel.Detailed && (
              <p className="text-sm text-muted-foreground">
                An in-depth playbook with 8-12 thorough steps for comprehensive
                execution
              </p>
            )}
          </div>
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={onClose} disabled={isGenerating}>
            Cancel
          </Button>
          <Button
            onClick={handleGenerate}
            disabled={!isValid || isGenerating}
            className="min-w-[140px]"
          >
            {isGenerating ? (
              <div className="flex items-center gap-2">
                <div className="w-4 h-4 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                Generating...
              </div>
            ) : (
              "Generate Playbook"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
