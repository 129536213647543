import { CompanyInfo } from "../components/company-info/models/CompanyInfo";
import { CompanyDocumentView } from "./CompanyDocument";

export class ChatMetadata {
  useNews: boolean;
  useInternet: boolean;
  companies: CompanyInfo[];
  filings: CompanyDocumentView[];
  transcripts: CompanyDocumentView[];
  userContentTitles: string[];

  get documents(): CompanyDocumentView[] {
    return [...this.filings, ...this.transcripts];
  }

  constructor(response: any) {
    this.useNews = response.useNews ?? false;
    this.useInternet = response.useInternet ?? false;
    this.companies = response.companies.map(
      (data: any) => new CompanyInfo(data),
    );
    this.filings = response.filings.map(
      (data: any) => new CompanyDocumentView(data),
    );
    this.transcripts = response.transcripts.map(
      (data: any) => new CompanyDocumentView(data),
    );
    this.userContentTitles = response.userContentTitles;
  }
}
