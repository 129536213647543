import { useEffect, useState, useCallback, useRef } from "react";
import { GetChatProps, useGetChats } from "./api/useGetChats";
import { useNavigate } from "react-router-dom";
import { Calendar, Building2, Search, X } from "lucide-react";
import ChatItemList from "./components/ChatItemList";
import { useEventListener } from "../../shared/hooks/useEventManagement";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { format } from "date-fns";
import { DateInterval, DateRange } from "react-day-picker";
import { Calendar as CalendarComponent } from "@/components/ui/calendar";
import { cn } from "@/lib/utils";
import { ChatShort } from "../../shared/models/Chat";
import { QCustomEvent } from "../../shared/hooks/useEventManagement";
import { useSearchCompanies } from "../../shared/hooks/useSearchCompanies";
import { CompanyInfo } from "../../shared/components/company-info/models/CompanyInfo";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useDebouncedValue } from "@mantine/hooks";
import { CommandLoading } from "cmdk";

interface ChatHistoryProps {
  userId?: string;
}

const ITEMS_PER_PAGE = 10;

export default function ChatManagement({ userId }: ChatHistoryProps) {
  const navigate = useNavigate();
  const { chats, getChats, isLoading: isChatsLoading } = useGetChats(userId);
  const [chatSearch, setChatSearch] = useState<string>();
  const [debouncedChatSearch] = useDebouncedValue(chatSearch, 300);
  const [selectedCompany, setSelectedCompany] = useState<CompanyInfo>();
  const [isCompanySearchOpen, setIsCompanySearchOpen] = useState(false);
  const [companySearch, setCompanySearch] = useState<string>();
  const [debouncedCompanySearch] = useDebouncedValue(companySearch, 300);
  const {
    companies,
    searchCompanies,
    isLoading: isCompaniesLoading,
    setCompanies,
  } = useSearchCompanies();
  const [dateRange, setDateRange] = useState<DateRange>();
  const [debouncedDateRange, setDebouncedDateRange] = useState<DateInterval>();
  const [hasMore, setHasMore] = useState(true);
  const [allChats, setAllChats] = useState<ChatShort[]>([]);
  const [searchChatProps, setSearchChatProps] = useState<GetChatProps>({
    page: 1,
    pageSize: ITEMS_PER_PAGE,
  });
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!debouncedCompanySearch) {
      setCompanies(null);
      return;
    }

    searchCompanies(debouncedCompanySearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCompanySearch]);

  useEffect(() => {
    if (!dateRange) {
      setDebouncedDateRange(undefined);
      return;
    }

    if (!dateRange.from || !dateRange.to) return;
    setDebouncedDateRange({
      after: dateRange.from,
      before: dateRange.to,
    });
  }, [dateRange]);

  useEffect(() => {
    // Skip the first render to avoid unnecessary API calls
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setAllChats([]);
    setHasMore(true);
    setSearchChatProps({
      page: 1,
      pageSize: ITEMS_PER_PAGE,
      companyId: selectedCompany?.id,
      query: chatSearch,
      from: debouncedDateRange?.after,
      to: debouncedDateRange?.before,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCompany,
    debouncedChatSearch,
    debouncedDateRange,
    setSearchChatProps,
  ]);

  useEffect(() => {
    getChats(searchChatProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchChatProps]);

  useEffect(() => {
    if (!chats) return;
    if (searchChatProps.page === 1) {
      setAllChats(chats);
    } else {
      setAllChats((prev) => [...prev, ...chats]);
      if (chats.length < ITEMS_PER_PAGE) {
        setHasMore(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chats]);

  const observer = useRef<IntersectionObserver>();
  const lastChatElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isChatsLoading || !hasMore) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setSearchChatProps((prevProps) => ({
            ...prevProps,
            page: searchChatProps.page + 1,
          }));
        }
      });

      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isChatsLoading, hasMore, setSearchChatProps],
  );

  // Listen for refresh events
  useEventListener(QCustomEvent.RefreshChats, () => {
    setSearchChatProps({
      page: 1,
      pageSize: ITEMS_PER_PAGE,
    });
    setAllChats([]);
    setHasMore(true);
  });

  const handleChatClick = useCallback(
    (chat: ChatShort) => {
      navigate(`/c/${chat.id}`);
    },
    [navigate],
  );

  const handleCompanySelect = useCallback((company: CompanyInfo) => {
    setSelectedCompany(company);
    setIsCompanySearchOpen(false);
    setCompanySearch("");
  }, []);

  const onChatDelete = (chatId: string) => {
    // Remove chat from the list
    setAllChats((prevChats) => prevChats.filter((chat) => chat.id !== chatId));
  };

  return (
    <div className="flex flex-col gap-6">
      {/* Filters */}
      <div className="flex items-center gap-3">
        {/* Date Range Filter */}
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className={cn(
                "justify-start text-left font-normal",
                !dateRange?.from && !dateRange?.to && "text-muted-foreground",
              )}
            >
              <Calendar className="mr-2 h-4 w-4" />
              {dateRange?.from &&
                !dateRange.to &&
                format(dateRange.from, "LLL dd, y")}
              {dateRange?.from && dateRange?.to && (
                <>
                  {format(dateRange.from, "LLL dd, y")} -{" "}
                  {format(dateRange.to, "LLL dd, y")}
                </>
              )}
              {!dateRange?.from && !dateRange?.to && "Pick a date range"}
              {dateRange && (
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-4 w-4 p-0 hover:bg-transparent"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDateRange(undefined);
                  }}
                >
                  <X className="h-3 w-3" />
                </Button>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <CalendarComponent
              initialFocus
              mode="range"
              selected={dateRange}
              onSelect={setDateRange}
              numberOfMonths={2}
            />
          </PopoverContent>
        </Popover>

        {/* Company Filter */}
        <div className="relative w-[300px]">
          <Popover
            open={isCompanySearchOpen}
            onOpenChange={setIsCompanySearchOpen}
          >
            <PopoverTrigger asChild>
              {selectedCompany ? (
                <Button
                  variant="outline"
                  role="combobox"
                  className="w-full justify-between"
                >
                  <div className="flex items-center gap-2">
                    <Avatar className="h-6 w-6">
                      <AvatarImage src={selectedCompany.logo} />
                      <AvatarFallback>
                        {selectedCompany.companyName[0]}
                      </AvatarFallback>
                    </Avatar>
                    <span>{selectedCompany.companyName}</span>
                  </div>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-4 w-4 p-0 hover:bg-transparent"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedCompany(undefined);
                    }}
                  >
                    <X className="h-3 w-3" />
                  </Button>
                </Button>
              ) : (
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={isCompanySearchOpen}
                  className="w-full justify-between"
                >
                  <div className="flex items-center gap-2">
                    <Building2 className="h-4 w-4 text-gray-400" />
                    <span className="text-muted-foreground">
                      Search company...
                    </span>
                  </div>
                </Button>
              )}
            </PopoverTrigger>
            <PopoverContent className="w-[300px] p-0" align="start">
              <Command shouldFilter={false}>
                <CommandInput
                  placeholder="Search company..."
                  value={companySearch}
                  onValueChange={setCompanySearch}
                />
                {isCompaniesLoading && (
                  <CommandLoading>
                    <div className="flex justify-center py-6">
                      <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
                    </div>
                  </CommandLoading>
                )}
                <CommandList>
                  {!debouncedCompanySearch && (
                    <CommandEmpty>Type to search companies...</CommandEmpty>
                  )}
                  {debouncedCompanySearch && (
                    <>
                      {companies && companies.length === 0 && (
                        <CommandEmpty>No companies found</CommandEmpty>
                      )}
                      {companies && companies.length > 0 && (
                        <CommandGroup heading="Companies">
                          {companies.map((company) => (
                            <CommandItem
                              key={company.id}
                              value={company.companyName}
                              onSelect={() => handleCompanySelect(company)}
                            >
                              <div className="flex items-center gap-2">
                                <Avatar className="h-6 w-6">
                                  <AvatarImage src={company.logo} />
                                  <AvatarFallback>
                                    {company.companyName[0]}
                                  </AvatarFallback>
                                </Avatar>
                                <span>{company.companyName}</span>
                                <span className="text-gray-500 text-sm">
                                  ({company.tickerSymbol})
                                </span>
                              </div>
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      )}
                    </>
                  )}
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
        </div>

        {/* Search Box */}
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
          <Input
            placeholder="Find research..."
            value={chatSearch}
            onChange={(e) => setChatSearch(e.target.value)}
            className="pl-9"
          />
        </div>
      </div>

      {/* Chat List */}
      <ChatItemList
        chats={allChats}
        userId={userId}
        lastChatRef={lastChatElementRef}
        onChatClick={handleChatClick}
        onChatDelete={onChatDelete}
      />

      {/* Loading indicator */}
      {isChatsLoading && (
        <div className="flex justify-center py-4">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      )}

      {/* No more chats indicator */}
      {!isChatsLoading && !hasMore && allChats.length > 0 && (
        <div className="text-center py-4 text-gray-500">
          No more chats to load
        </div>
      )}

      {/* Empty state */}
      {!isChatsLoading && allChats.length === 0 && (
        <div className="text-center py-12">
          <p className="text-gray-500">No chats found</p>
        </div>
      )}
    </div>
  );
}
