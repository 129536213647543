import Markdown, { ExtraProps } from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { useEffect, useState } from "react";
import { Citation, CitationIndex } from "./models/Citation";
import { ContentWithCitations } from "./models/ContentWithCitations";
import { Button } from "@/components/ui/button";
import { downloadFile } from "../../shared/utils/Files";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import { useClipboard, useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../shared/utils/ResponsiveStyles";
import { cn } from "@/lib/utils";

interface MarkdownWithCitationsProps {
  content: string;
  className?: string;
  onCitationClick?: (citation: CitationIndex) => void;
}

export function MarkdownWithCitations({
  content,
  className,
  onCitationClick,
}: MarkdownWithCitationsProps) {
  const [citations, setCitations] = useState<Citation[]>([]);
  const [markdownContent, setMarkdownContent] = useState<string>("");
  const clipboard = useClipboard({ timeout: 2000 });

  useEffect(() => {
    // Extract citations from the content
    const contentWithCitations = new ContentWithCitations(content);

    setCitations(contentWithCitations.citations);

    // Process content to handle math equations and currency symbols
    const processedText = contentWithCitations.formattedContent
      // Escape dollar signs for currency
      .replace(/\$/g, "\\$")
      // Replace escaped brackets with dollar signs for block math
      .replace(/\\\[/g, "$$")
      .replace(/\\\]/g, "$$");

    setMarkdownContent(processedText);
  }, [content]);

  const formatCitation = (
    ops: React.ClassAttributes<HTMLElement> &
      React.HTMLAttributes<HTMLElement> &
      ExtraProps,
  ) => {
    const defaultReturn = <code>{ops.children}</code>;
    if (typeof ops.children !== "string") return defaultReturn;

    const match = ops.children.match(/\[citation:(\d+)\]/);
    if (!match) return defaultReturn;

    const citationIndex = parseInt(match[1]);
    if (isNaN(citationIndex)) return defaultReturn;

    const citation = citations[citationIndex];
    if (!citation) return defaultReturn;

    return (
      <button
        className={cn(
          "cursor-pointer min-w-[12px] p-[2px] text-xs font-medium leading-[135%]",
          "inline-flex justify-center items-center rounded-[2px]",
          "bg-primary/10 hover:bg-primary/20",
        )}
        onClick={() =>
          onCitationClick?.(new CitationIndex(citationIndex, citation))
        }
      >
        {citationIndex + 1}
      </button>
    );
  };

  const formatText = (
    ops: React.ClassAttributes<HTMLElement> &
      React.HTMLAttributes<HTMLElement> &
      ExtraProps,
  ) => {
    const text = ops.children as string;
    if (!text || typeof text !== "string") return <>{ops.children}</>;

    // Split text by citation markers
    const parts = text.split(/(\[citation:\d+\])/);

    return (
      <>
        {parts.map((part, i) => {
          const citationMatch = part.match(/\[citation:(\d+)\]/);
          if (citationMatch) {
            const citationIndex = parseInt(citationMatch[1]);
            const citation = citations[citationIndex];
            if (citation) {
              return (
                <button
                  key={i}
                  className={cn(
                    "cursor-pointer min-w-[12px] p-[2px] text-xs font-medium leading-[135%]",
                    "inline-flex justify-center items-center rounded-[2px]",
                    "bg-primary/10 hover:bg-primary/20",
                  )}
                  onClick={() =>
                    onCitationClick?.(
                      new CitationIndex(citationIndex, citation),
                    )
                  }
                >
                  {citationIndex + 1}
                </button>
              );
            }
          }
          return <span key={i}>{part}</span>;
        })}
      </>
    );
  };

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  const formatTable = (
    ops: React.ClassAttributes<HTMLTableElement> &
      React.TableHTMLAttributes<HTMLTableElement> &
      ExtraProps,
  ) => {
    const tableContent = ops.children;

    const extractTableData = (format: "csv" | "tsv"): string => {
      if (!tableContent) return "";

      let outputContent = "";
      const delimiter = format === "csv" ? "," : "\t"; // Choose separator based on format

      const extractText = (node: any): string => {
        if (typeof node === "string") return node.trim();
        if (Array.isArray(node)) return node.map(extractText).join(" ").trim();
        if (node && typeof node === "object") {
          if (node.props && node.props.children) {
            return extractText(node.props.children);
          }
          if (node.type === "text") return node.value.trim();
        }
        return "";
      };

      const processCell = (cell: string): string => {
        let processed = cell
          .replace(/\*\*/g, "") // Remove bold Markdown
          .replace(/<br\s*\/?>/g, " ") // Replace <br> with space
          .replace(/\[\d+]/g, "") // Remove citation numbers
          .replace(/\t/g, " ") // Replace tab characters with space
          .replace(/\[citation:\d+\]/g, ""); // Remove "[citation:0]"

        if (format === "csv") {
          processed = processed.replace(/"/g, '""'); // Escape quotes for CSV
          return `"${processed}"`; // Wrap CSV values in quotes
        }
        return processed; // For TSV, return as is
      };

      const processRow = (row: any): string => {
        if (!row || !row.props || !row.props.children) return "";

        const cells = Array.isArray(row.props.children)
          ? row.props.children
          : [row.props.children];
        return cells
          .map((cell: any) => processCell(extractText(cell)))
          .join(delimiter); // Use chosen separator
      };

      // Ensure tableContent is an array
      const parts = Array.isArray(tableContent) ? tableContent : [tableContent];

      parts.forEach((part) => {
        if (part && part.type === "tr") {
          outputContent += processRow(part) + "\n";
        } else if (part && part.props && part.props.children) {
          const rows = Array.isArray(part.props.children)
            ? part.props.children
            : [part.props.children];
          rows.forEach((row: any) => {
            if (row && row.type === "tr") {
              outputContent += processRow(row) + "\n";
            }
          });
        }
      });

      return outputContent;
    };

    const downloadCSV = () => {
      const csvContent = extractTableData("csv");
      downloadFile(csvContent, `table-${Date.now()}.csv`, "text/csv");
    };

    const copyTSV = () => {
      const tsvContent = extractTableData("tsv");
      clipboard.copy(tsvContent);
    };

    return (
      <div>
        <table {...ops}>{tableContent}</table>
        <Button
          variant="outline"
          size={isLargeScreen ? "default" : "sm"}
          className={cn(
            "mt-1.5 px-1.5 mr-2",
            isLargeScreen ? "h-[35px]" : "h-[25px]",
            "rounded-[2px]",
          )}
          onClick={copyTSV}
        >
          {clipboard.copied ? "Copied" : "Copy table"}
        </Button>
        <Button
          variant="outline"
          size={isLargeScreen ? "default" : "sm"}
          className={cn(
            "mt-1.5 px-1.5",
            isLargeScreen ? "h-[35px]" : "h-[25px]",
            "rounded-[2px]",
          )}
          onClick={downloadCSV}
        >
          Download CSV
        </Button>
      </div>
    );
  };

  return (
    <Markdown
      remarkPlugins={[remarkGfm, remarkMath]}
      rehypePlugins={[rehypeRaw, rehypeKatex]}
      className={className}
      children={markdownContent}
      components={{
        code: formatCitation,
        table: formatTable,
        p: formatText,
        img: () => null,
      }}
    />
  );
}
