import { useApiPrivateRequest } from "../hooks/useApi";
import { ChatFeatures } from "../models/ChatFeatures";

export const useGetChatFeatures = () => {
  const {
    data: features,
    call,
    isLoading,
  } = useApiPrivateRequest<ChatFeatures>(
    (response) => new ChatFeatures(response),
  );

  const getFeatures = async () => {
    await call("/chat/features", "GET");
  };

  return { features, getFeatures, isLoading };
};
