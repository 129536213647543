export enum StepType {
  UnhandledError = "UnhandledError",
  UserInput = "UserInput",
  ModelOutput = "ModelOutput",
  NewsLoading = "NewsLoading",
  UserContent = "UserContent",
  ExternalContextUserContent = "ExternalContextUserContent",
  ExternalContextMultipleModelsUserContent = "ExternalContextMultipleModelsUserContent",
  ExternalContextSeparateQuestionsUserContent = "ExternalContextSeparateQuestionsUserContent",
}
