import { useState, useEffect } from "react";
import { Input } from "@/components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Search, ChevronDown, ChevronUp } from "lucide-react";
import { Playbook, PlaybookShort } from "../model/Playbook";
import { useGetPlaybooks } from "../api/useGetPlaybooks";
import { useGetDefaultPlaybooks } from "../api/useGetDefaultPlaybooks";
import { PlaybookCardv2 } from "./PlaybookCardv2";

interface PlaybookSelectionModalProps {
  open: boolean;
  onClose: () => void;
  onSelect: (playbook: Playbook | PlaybookShort, isDefault: boolean) => void;
}

export function PlaybookSelectionModal({
  open,
  onClose,
  onSelect,
}: PlaybookSelectionModalProps) {
  const { playbooks, getPlaybooks } = useGetPlaybooks();
  const { defaultPlaybooks, getDefaultPlaybooks } = useGetDefaultPlaybooks();

  const [searchQuery, setSearchQuery] = useState("");
  const [showAllDefaults, setShowAllDefaults] = useState(false);
  const [showAllCustom, setShowAllCustom] = useState(false);

  useEffect(() => {
    if (open) {
      getPlaybooks();
      getDefaultPlaybooks();
    } else {
      // Reset all states when the main modal is closed
      setSearchQuery("");
      setShowAllDefaults(false);
      setShowAllCustom(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // Filter playbooks based on search query
  const filteredDefaultPlaybooks =
    defaultPlaybooks?.filter((playbook) =>
      playbook.title.toLowerCase().includes(searchQuery.toLowerCase()),
    ) || [];

  const filteredPlaybooks =
    playbooks?.filter((playbook) =>
      playbook.title.toLowerCase().includes(searchQuery.toLowerCase()),
    ) || [];

  // Show only 3 playbooks when not expanded
  const displayedDefaultPlaybooks = showAllDefaults
    ? filteredDefaultPlaybooks
    : filteredDefaultPlaybooks.slice(0, 3);

  const displayedPlaybooks = showAllCustom
    ? filteredPlaybooks
    : filteredPlaybooks.slice(0, 3);

  // Show "Show All" button if there are more than 3 playbooks
  const hasMoreDefaultPlaybooks = filteredDefaultPlaybooks.length > 3;
  const hasMoreCustomPlaybooks = filteredPlaybooks.length > 3;

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-[70rem] w-[90vw] max-h-[90vh] flex flex-col p-0">
        <DialogHeader className="px-6 py-4 border-b shrink-0">
          <DialogTitle>Select a Playbook</DialogTitle>
          <DialogDescription>
            Choose from our default playbooks or create your own custom
            playbook.
          </DialogDescription>
        </DialogHeader>

        <div className="flex-1 min-h-0 flex flex-col">
          {/* Search Bar */}
          <div className="px-6 py-4 border-b bg-white shrink-0">
            <div className="flex items-center gap-4">
              <div className="relative flex-1">
                <Search className="absolute left-3 top-1/2 size-4 -translate-y-1/2 text-muted-foreground" />
                <Input
                  placeholder="Search playbooks..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="pl-9"
                />
              </div>
            </div>
          </div>

          {/* Scrollable Content */}
          <div className="flex-1 overflow-y-auto">
            <div className="px-6 py-4 space-y-6">
              {/* Default Playbooks Section */}
              <div>
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-lg font-semibold">Default Playbooks</h3>
                  {hasMoreDefaultPlaybooks && (
                    <Button
                      variant="ghost"
                      className="gap-2 text-muted-foreground"
                      onClick={() => setShowAllDefaults(!showAllDefaults)}
                    >
                      {showAllDefaults ? (
                        <>
                          Show Less
                          <ChevronUp className="size-4" />
                        </>
                      ) : (
                        <>
                          Show All ({filteredDefaultPlaybooks.length})
                          <ChevronDown className="size-4" />
                        </>
                      )}
                    </Button>
                  )}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {displayedDefaultPlaybooks.map((playbook) => (
                    <PlaybookCardv2
                      key={playbook.id}
                      playbook={playbook}
                      isDefault={true}
                      setActivePlaybook={(p) => p && onSelect(p, true)}
                      setIsDefault={() => {}}
                      onDelete={() => {}}
                      descriptionCutoff={250}
                    />
                  ))}
                </div>
              </div>

              {/* Custom Playbooks Section */}
              {playbooks && playbooks.length > 0 && (
                <div>
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-semibold">Your Playbooks</h3>
                    {hasMoreCustomPlaybooks && (
                      <Button
                        variant="ghost"
                        className="gap-2 text-muted-foreground"
                        onClick={() => setShowAllCustom(!showAllCustom)}
                      >
                        {showAllCustom ? (
                          <>
                            Show Less
                            <ChevronUp className="size-4" />
                          </>
                        ) : (
                          <>
                            Show All ({filteredPlaybooks.length})
                            <ChevronDown className="size-4" />
                          </>
                        )}
                      </Button>
                    )}
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {displayedPlaybooks.map((playbook) => (
                      <PlaybookCardv2
                        key={playbook.id}
                        playbook={playbook}
                        isDefault={false}
                        setActivePlaybook={(p) => onSelect(p, false)}
                        setIsDefault={() => {}}
                        onDelete={getPlaybooks}
                        descriptionCutoff={250}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
