import { Switch } from "@/components/ui/switch";
import { Globe } from "lucide-react";

interface UseWebSearchToggleProps {
  includeWeb: boolean;
  setIncludeWeb: (value: boolean) => void;
  disabled?: boolean;
}

export function UseWebSearchToggle({
  includeWeb,
  setIncludeWeb,
  disabled = false,
}: UseWebSearchToggleProps) {
  return (
    <button
      onClick={() => setIncludeWeb(!includeWeb)}
      disabled={disabled}
      className={`w-full transition-colors 
             ${includeWeb ? "bg-blue-50 border-blue-200" : "bg-gray-50 border-gray-200"} 
             border-2 rounded-lg p-4 hover:bg-blue-50/70 group`}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div
            className={`p-2 rounded-lg ${includeWeb ? "bg-blue-100" : "bg-gray-100"}`}
          >
            <Globe
              className={`h-5 w-5 ${includeWeb ? "text-blue-600" : "text-gray-600"}`}
            />
          </div>
          <div className="text-left">
            <h3
              className={`font-medium ${includeWeb ? "text-blue-900" : "text-gray-900"}`}
            >
              Web Access
            </h3>
            <p
              className={`text-sm ${includeWeb ? "text-blue-600" : "text-gray-600"}`}
            >
              AI will search the web
            </p>
          </div>
        </div>
        <Switch
          checked={includeWeb}
          onCheckedChange={setIncludeWeb}
          className={includeWeb ? "bg-blue-500" : ""}
          disabled={disabled}
        />
      </div>
    </button>
  );
}
