import { useApiPrivateRequest } from "./useApi";
import { CompanyInfo } from "../components/company-info/models/CompanyInfo";

export const useSearchCompanies = () => {
  const {
    data: companies,
    call,
    isLoading,
    isFailed,
    setData,
  } = useApiPrivateRequest<CompanyInfo[]>((response) => {
    return response.map((item: any) => new CompanyInfo(item));
  });

  const searchCompanies = async (input: string) => {
    const result = await call(`/marketdata/companies/search`, "GET", {
      input,
    });
    return result;
  };

  return {
    companies,
    searchCompanies,
    isLoading,
    isFailed,
    setCompanies: setData,
  };
};
