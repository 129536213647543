import { UserContentType } from "../../../shared/enums/UserContentType";

export class UserContentChunkText {
  id: string;
  title: string;
  url?: string;
  chunkId?: number;
  chunkText?: string;
  type?: UserContentType;
  page?: number;
  boundingBox?: {
    xMin: number;
    yMin: number;
    xMax: number;
    yMax: number;
  };

  constructor(data: any) {
    this.id = data.id;
    this.title = data.title;
    this.url = data.url;
    this.chunkId = data.chunkId;
    this.chunkText = data.chunkText;
    this.type = data.type;
    this.page = data.page;
    this.boundingBox = data.boundingBox;
  }
}
