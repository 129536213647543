import { Auth0Provider } from "@auth0/auth0-react";
import { ReactNode } from "react";
import { AuthServiceContext } from "../../AuthServiceContext";
import { useAuth0Service } from "./useAuth0Service";

export const Auth0AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN as string}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID as string}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE as string,
      }}
    >
      <Auth0ServiceProvider>{children}</Auth0ServiceProvider>
    </Auth0Provider>
  );
};

const Auth0ServiceProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const auth0Service = useAuth0Service();
  return (
    <AuthServiceContext.Provider value={auth0Service}>
      {children}
    </AuthServiceContext.Provider>
  );
};
