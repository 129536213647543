import { useContext } from "react";
import AuthService from "../../models/AuthService";
import User from "../../models/User";
import { KeycloakContext } from "./KeycloakContext";
import { UserRole } from "@/shared/enums/UserRole";

export const useKeycloakService = (): AuthService => {
  const { keycloak, initialized } = useContext(KeycloakContext);

  const getUserProfile = (): User | null => {
    if (!keycloak.authenticated || !keycloak.tokenParsed) {
      return null;
    }

    return {
      sub: keycloak.tokenParsed.sub,
      email: keycloak.tokenParsed.email,
      name:
        keycloak.tokenParsed.name || keycloak.tokenParsed.preferred_username,
      picture: keycloak.tokenParsed.picture,
    };
  };

  const loginWithRedirect = async () => {
    // default method already redirects
    await keycloak.login();
  };

  const logout = async () => {
    if (!keycloak.authenticated) return;
    await keycloak.logout();
  };
  const logoutWithRedirect = async () => {
    // default method already redirects
    await logout();
  };

  const getAccessToken = async (): Promise<string> => {
    try {
      if (keycloak.token && keycloak.isTokenExpired(30)) {
        await keycloak.updateToken(60);
      }
      return keycloak.token || "";
    } catch (error) {
      console.error("Failed to get access token", error);
      throw error;
    }
  };

  const hasRole = (role: UserRole) => {
    if (!keycloak.clientId) return false;
    const roles =
      keycloak.tokenParsed?.resource_access?.[keycloak.clientId]?.roles;
    return roles?.includes(role) || false;
  };

  return {
    isAuthenticated: initialized && !!keycloak.authenticated,
    isLoading: !initialized,
    user: getUserProfile(),
    loginWithRedirect,
    logout,
    logoutWithRedirect,
    getAccessToken,
    hasRole,
  };
};
