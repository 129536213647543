import { useApiPrivateRequest } from "../../../shared/hooks/useApi";

export const useSharePlaybook = () => {
  const { call, isLoading, isFailed } = useApiPrivateRequest<boolean>(
    (_) => true,
  );

  const sharePlaybook = async (playbookId: string, email: string) => {
    await call(
      `/playbook/share/${playbookId}?email=${encodeURIComponent(email)}`,
      "GET",
    );
  };

  return { sharePlaybook, isLoading, isFailed };
};
