import { useApiPrivateRequest } from "../../../shared/hooks/useApi";

export const useGetPresignedUrl = () => {
  const {
    data: presignedUrl,
    call,
    isLoading,
    isFailed,
  } = useApiPrivateRequest<string>((response) => response);

  const getPresignedUrl = async (chatId: string, contentId: string) => {
    await call(`chat/${chatId}/userContent/${contentId}/presignedUrl`, "GET");
  };

  return { presignedUrl, getPresignedUrl, isLoading, isFailed };
};
