import { useApiPrivateRequest } from "../../../shared/hooks/useApi";

export const useGetCompanyYears = () => {
  const {
    data: years,
    call,
    isLoading,
    isFailed,
  } = useApiPrivateRequest<number[]>((response) => {
    return response.map((item: any) => parseInt(item));
  });

  const getCompanyYears = async (companyId: number) => {
    await call(`/marketdata/companies/${companyId}/years`, "GET");
  };

  return {
    years,
    getCompanyYears,
    isLoading,
    isFailed,
  };
};
