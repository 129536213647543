import { ChatUpdateType } from "../enums/ChatUpdateType";
import { useSignalRConnection } from "@/shared/hooks/userSignalRConnection";

export const useChatUpdates = (
  chatId: string,
  callback: (type: ChatUpdateType, data: any) => void,
): void => {
  useSignalRConnection({
    hubUrl: "/chatUpdatesHub",
    channelName: `${chatId}:chat`,
    logPrefix: " for Chat",
    handleMessage: (name: string, data: string) => {
      callback(name as ChatUpdateType, JSON.parse(data));
    },
    dependencies: [chatId],
  });
};
