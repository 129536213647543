import { default as App } from "./App";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import { hotjar } from "react-hotjar";

import * as amplitude from "@amplitude/analytics-browser";

// Import global styles
import "./styles/index.css";
import { TooltipProvider } from "./components/ui/tooltip";
import { Toaster } from "sonner";
import { AuthProvider } from "./shared/auth/AuthProvider";

if (
  import.meta.env.VITE_ENVIRONMENT !== "DEV" &&
  import.meta.env.VITE_GA4_ID &&
  import.meta.env.VITE_AMPLITUDE_ID
) {
  ReactGA.initialize(import.meta.env.VITE_GA4_ID);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  hotjar.initialize(import.meta.env.VITE_HJ_ID, import.meta.env.VITE_HJ_SV);
  amplitude.init(import.meta.env.VITE_AMPLITUDE_ID, { defaultTracking: true });
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <TooltipProvider>
          <App />
          <Toaster expand={true} richColors={true} />
        </TooltipProvider>
      </AuthProvider>
    </BrowserRouter>
  </StrictMode>,
);
