import { ModelOutputMessageData } from "../../models/message-data/ModelOutputMessageData";
import { MarkdownWithCitations } from "../../../sources/MarkdownWithCitations";
import markdownClasses from "../../../../shared/styles/Markdown.module.css";
import { CitationIndex } from "../../../sources/models/Citation";

interface ModelOutputMessageProps {
  data: ModelOutputMessageData;
  onCitationClick?: (citation: CitationIndex) => void;
}

export default function ModelOutputMessage({
  data,
  onCitationClick,
}: ModelOutputMessageProps) {
  return (
    <div
      className={`${markdownClasses["markdown"]} text-sm leading-relaxed prose prose-sm max-w-none prose-p:leading-relaxed prose-pre:my-0`}
    >
      <MarkdownWithCitations
        content={data.content}
        onCitationClick={onCitationClick}
      />
    </div>
  );
}
