import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PlaybookList from "../features/playbook/components/PlaybookList";
import { PlaybookShort } from "../features/playbook/model/Playbook";
import { AddContextModal } from "../features/chat-management/components/AddContextModal";
import { useCreatePlaybookChat } from "../features/playbook/api/useCreatePlaybookChat";
import ChatContext from "@/shared/models/ChatContext";

export default function Playbooks() {
  const navigate = useNavigate();
  const [activePlaybook, setActivePlaybook] = useState<PlaybookShort>();
  const [isDefault, setIsDefault] = useState(false);
  const [showContext, setShowContext] = useState(false);
  const { createChat, chat } = useCreatePlaybookChat();

  useEffect(() => {
    if (!chat) return;
    navigate(`/c/${chat.id}`);
  }, [chat, navigate]);

  const handlePlaybookSelect = (
    playbook: PlaybookShort | undefined,
    isDefault: boolean,
  ) => {
    setActivePlaybook(playbook);
    setIsDefault(isDefault);
    if (playbook) {
      setShowContext(true);
    }
  };

  const handleContextComplete = async (contextData: ChatContext) => {
    if (!activePlaybook) return;

    await createChat(
      activePlaybook.id,
      isDefault,
      activePlaybook.title,
      false, // sendPdf
      contextData,
    );
    setShowContext(false);
  };

  return (
    <>
      <PlaybookList
        setActivePlaybook={handlePlaybookSelect}
        setIsDefault={setIsDefault}
      />
      {showContext && (
        <AddContextModal
          open={showContext}
          onClose={() => setShowContext(false)}
          onComplete={handleContextComplete}
          playbookId={activePlaybook?.id}
          isDefaultPlaybook={isDefault}
        />
      )}
    </>
  );
}
