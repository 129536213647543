import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { Playbook } from "../model/Playbook";

export const useDuplicatePlaybook = () => {
  const {
    data: duplicatedPlaybook,
    call,
    isLoading: isDuplicating,
    isFailed: isDuplicateFailed,
  } = useApiPrivateRequest<Playbook>((response) => new Playbook(response));

  const duplicatePlaybook = async (id: string, isDefault: boolean) => {
    await call(`/playbook/${id}/duplicate?isDefault=${isDefault}`, "POST");
  };

  return {
    duplicatedPlaybook,
    duplicatePlaybook,
    isDuplicating,
    isDuplicateFailed,
  };
};
