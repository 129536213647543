import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { ChatReport } from "../../../shared/models/ChatReport";
import { ChatReportFormat } from "@/shared/enums/ChatReportFormat";

export const useCreateReport = (chatId: string) => {
  const { data: newReport, call } = useApiPrivateRequest<ChatReport>();
  const createReport = async (format: ChatReportFormat) => {
    await call(`/chat/${chatId}/report`, "POST", {
      format: format.replace(" ", ""),
    });
  };

  return { newReport, createReport };
};
