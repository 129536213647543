import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useGetPrimaryGenericMlModelsInfo } from "@/shared/api/useGetPrimaryGenericMlModelsInfo";
import { useEffect } from "react";

interface ModelSelectorProps {
  selectedModel: string | undefined;
  onModelChange: (model: string) => void;
}

function MetricBars({ value, label }: { value: number; label: string }) {
  return (
    <div className="flex items-center gap-2">
      <span className="text-xs text-gray-500 w-[60px]">{label}</span>
      <div className="flex gap-1">
        {[1, 2, 3, 4, 5].map((level) => (
          <div
            key={level}
            className={`w-[18px] h-[4px] rounded-sm transition-colors ${
              level <= value ? "bg-gray-800" : "bg-gray-200"
            }`}
          />
        ))}
      </div>
    </div>
  );
}

export function ModelSelector({
  selectedModel,
  onModelChange,
}: ModelSelectorProps) {
  const { mlModelsInfo, getMlModelsInfo } = useGetPrimaryGenericMlModelsInfo();
  const selectedModelOption = mlModelsInfo?.mlModelInfos.find(
    (option) => option.mlModel === selectedModel,
  );

  useEffect(() => {
    getMlModelsInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mlModelsInfo?.selectedMlModel) {
      onModelChange(mlModelsInfo.selectedMlModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mlModelsInfo]);

  return (
    <div className="flex items-center gap-2">
      <span className="text-sm font-medium text-gray-700">Choose model:</span>
      <Select value={selectedModel || ""} onValueChange={onModelChange}>
        <SelectTrigger className="w-[300px] h-9 bg-white text-sm">
          <SelectValue>
            {selectedModelOption?.label || "Select a model"}
          </SelectValue>
        </SelectTrigger>
        <SelectContent>
          {mlModelsInfo?.mlModelInfos.map((option) => (
            <SelectItem
              key={option.mlModel}
              value={option.mlModel}
              className="text-sm py-3 cursor-pointer hover:bg-gray-50"
            >
              <div className="space-y-2">
                <div>
                  <div className="font-medium">{option.label}</div>
                  <div className="text-xs text-gray-500 mt-0.5">
                    {option.description}
                  </div>
                </div>
                <div className="space-y-2">
                  <MetricBars
                    value={option.intelligence}
                    label="Intelligence"
                  />
                  <MetricBars value={option.speed} label="Speed" />
                </div>
              </div>
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
