import React, { ReactNode, useEffect, useState, useRef } from "react";
import { KeycloakContext } from "./KeycloakContext";
import { AuthServiceContext } from "../../AuthServiceContext";
import { useKeycloakService } from "./useKeycloakService";
import Keycloak from "keycloak-js";

export const KeycloakAuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [keycloak] = useState(
    new Keycloak({
      url: import.meta.env.VITE_KEYCLOAK_URL as string,
      realm: import.meta.env.VITE_KEYCLOAK_REALM as string,
      clientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID as string,
    }),
  );
  const [initialized, setInitialized] = useState(false);
  const initAttempted = useRef(false);

  useEffect(() => {
    const initKeycloak = async () => {
      if (initAttempted.current) return;
      initAttempted.current = true;

      await keycloak.init({
        onLoad: "login-required",
        pkceMethod: "S256",
        checkLoginIframe: true,
      });

      setInitialized(true);
    };

    initKeycloak();
  }, [keycloak]);

  return (
    <KeycloakContext.Provider value={{ keycloak, initialized }}>
      <KeycloakServiceProvider>{children}</KeycloakServiceProvider>
    </KeycloakContext.Provider>
  );
};

const KeycloakServiceProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const keycloakService = useKeycloakService();
  return (
    <AuthServiceContext.Provider value={keycloakService}>
      {children}
    </AuthServiceContext.Provider>
  );
};
