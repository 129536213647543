import { useLocalStorage } from "@mantine/hooks";
import { LocalStorageKeys } from "../../../shared/enums/LocalStorageKeys";
import { CitationIndex } from "../../sources/models/Citation";
import { useMemo } from "react";
import { IconX } from "@tabler/icons-react";
import { SidebarDocumentCitation } from "./sources-sidebar/SidebarDocumentCitation";
import { CitationSourceType } from "../../../shared/enums/CitationSourceType";
import { SidebarNewsCitation } from "./sources-sidebar/SidebarNewsCitation";
import { SidebarEstimateCitation } from "./sources-sidebar/SidebarEstimateCitation";
import { SidebarUserContentCitation } from "./sources-sidebar/SidebarUserContentCitation";
import { SidebarWebCitation } from "./sources-sidebar/SidebarWebCitation";
import { Button } from "@/components/ui/button";
import { ChatUserContent } from "../models/ChatUserContent";
import { cn } from "@/lib/utils";

interface CitationOverviewProps {
  citation: CitationIndex;
  close: () => void;
  chatId: string;
  userContents: ChatUserContent[];
}

export function CitationOverview({
  citation,
  close,
  chatId,
  userContents,
}: CitationOverviewProps) {
  const [, , clearSidebarCitation] = useLocalStorage<CitationIndex>({
    key: LocalStorageKeys.SidebarCitation,
  });
  const memoizedCitation = useMemo(() => citation, [citation]);

  const handleClose = () => {
    clearSidebarCitation();
    close();
  };

  function getUserContent(): ChatUserContent | undefined {
    if (!userContents || !memoizedCitation.documentId) {
      return undefined;
    }
    return userContents.find(
      (content) => content.id === memoizedCitation.documentId,
    );
  }

  function selectCitationComponent() {
    const userContent = getUserContent();

    switch (memoizedCitation.sourceType) {
      case CitationSourceType.News:
        return <SidebarNewsCitation citation={memoizedCitation} />;
      case CitationSourceType.Filing:
      case CitationSourceType.Transcript:
        return <SidebarDocumentCitation citation={memoizedCitation} />;
      case CitationSourceType.Estimates:
        return <SidebarEstimateCitation citation={memoizedCitation} />;
      case CitationSourceType.UserContent:
        if (!userContent) {
          console.error(
            "User content not found for citation:",
            memoizedCitation,
          );
          return null;
        }
        return (
          <SidebarUserContentCitation
            citation={memoizedCitation}
            chatId={chatId}
          />
        );
      case CitationSourceType.Internet:
        return (
          <SidebarWebCitation citation={memoizedCitation} chatId={chatId} />
        );
    }
  }

  return (
    <div className="h-full flex flex-col">
      <div className="flex justify-between mb-1 pt-3">
        <div
          className={cn(
            "min-w-[12px] p-[2px] mr-3 text-xs font-medium leading-[135%]",
            "inline-flex justify-center items-center rounded-[2px]",
            "bg-primary/10",
          )}
        >
          {memoizedCitation.index + 1}
        </div>
        <Button
          variant="ghost"
          size="sm"
          className="h-6 w-6 p-0"
          onClick={handleClose}
        >
          <IconX className="h-3.5 w-3.5" />
        </Button>
      </div>
      <div className="flex-grow overflow-hidden">
        {selectCitationComponent()}
      </div>
    </div>
  );
}
