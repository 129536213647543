import { useGetUserContentChunk } from "../../../sources/api/useGetUserContentChunk";
import { CitationIndex } from "../../../sources/models/Citation";
import { useEffect, useMemo } from "react";
import { parseIntOrUndefined } from "../../../../shared/utils/Number";
import CenterLoader from "../../../../shared/components/loader/CenterLoader";
import SidebarUserContent from "./SidebarUserContent";

interface SidebarUserContentCitationProps {
  citation: CitationIndex;
  chatId: string;
}

export function SidebarUserContentCitation({
  citation,
  chatId,
}: SidebarUserContentCitationProps) {
  const {
    source,
    getUserContentChunkText,
    isLoading: isLoadingContent,
  } = useGetUserContentChunk();

  const memoizedCitation = useMemo(() => citation, [citation]);

  useEffect(() => {
    getUserContentChunkText(
      memoizedCitation.sourceType,
      memoizedCitation.documentId,
      chatId,
      parseIntOrUndefined(memoizedCitation.chunkId),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedCitation, chatId]);

  return (
    <>
      {isLoadingContent && <CenterLoader />}
      <div className="h-full overflow-hidden">
        {source && (
          <SidebarUserContent
            chatId={chatId}
            contentId={source.id}
            chunkText={source.chunkText}
            pageNumber={source.page}
            boundingBox={source.boundingBox}
          />
        )}
      </div>
    </>
  );
}
