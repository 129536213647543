import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { WebResult } from "../models/WebResult";

export const useGetMultipleWebResult = () => {
  const {
    data: webResults,
    call,
    isLoading,
    isFailed,
  } = useApiPrivateRequest<WebResult[]>((response) =>
    response.map((document: any) => new WebResult(document)),
  );

  const getWebResults = async (chatId: string, webResultIds: string[]) => {
    await call(`chat/${chatId}/webResult`, "GET", { webResultIds });
  };

  return { webResults, getWebResults, isLoading, isFailed };
};
