import classes from "../styles/Settings.module.css";
import { SettingsDataItem } from "../models/UserSettings";
import { useUpdateSettings } from "../api/useUpdateSettings";
import { useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";

interface SettingsItemProps {
  setting: SettingsDataItem;
  onChange: (title: string, value: string) => void;
}

export default function SettingsItem({ setting, onChange }: SettingsItemProps) {
  const [, setValue] = useState<string>(setting.value);
  const { updateSettings } = useUpdateSettings(setting.key);

  const handleChange = async (newValue: string) => {
    setValue(newValue);
    onChange(setting.key, newValue);
    await updateSettings(newValue);
  };

  return (
    <div className="space-y-2">
      <Label>{setting.label}</Label>
      <Select value={setting.value} onValueChange={handleChange}>
        <SelectTrigger className={classes["select-input"]}>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          {setting.possibleValues.map((value) => (
            <SelectItem key={value} value={value}>
              {value}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
