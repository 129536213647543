import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Trash2, Eye, Star, Share } from "lucide-react";
import { Playbook } from "../model/Playbook";
import { useDeletePlaybook } from "../api/useDeletePlaybook";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from "@/components/ui/alert-dialog";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSharePlaybook } from "../api/useSharePlaybook";
import { PlaybookShareModal } from "./PlaybookShareModal";

interface PlaybookCardv2Props {
  playbook: Playbook;
  isDefault: boolean;
  isEditable?: boolean;
  setActivePlaybook: (playbook: Playbook, isDefault: boolean) => void;
  setIsDefault: (isDefault: boolean) => void;
  onDelete?: () => void;
  onStar?: (playbookId: string, starred: boolean) => Promise<void>;
  descriptionCutoff?: number;
}

export function PlaybookCardv2({
  playbook,
  isDefault,
  isEditable,
  setActivePlaybook,
  onDelete,
  onStar,
  descriptionCutoff = 500,
}: PlaybookCardv2Props) {
  const { deletePlaybook, isDeleting } = useDeletePlaybook();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const {
    sharePlaybook,
    isLoading: isSharing,
    isFailed: isShareFailed,
  } = useSharePlaybook();
  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
      await deletePlaybook(playbook.id);
      setShowDeleteAlert(false);
      onDelete?.();
    } catch (error) {
      console.error("Failed to delete playbook:", error);
    }
  };

  return (
    <>
      <Card key={playbook.id} className="p-6 flex flex-col h-full">
        <div className="flex items-start justify-between">
          <div className="flex flex-col">
            <h3 className="text-lg font-semibold text-gray-900">
              {playbook.title}
            </h3>
          </div>
          <div className="flex items-start gap-2">
            {!isDefault && onStar && (
              <Button
                variant="ghost"
                size="icon"
                className={`${playbook.starred ? "text-yellow-400" : "text-gray-400"} hover:text-yellow-500`}
                onClick={() => {
                  if (playbook.starred === undefined) return;
                  onStar(playbook.id, playbook.starred);
                }}
              >
                <Star
                  className="h-4 w-4"
                  fill={playbook.starred ? "currentColor" : "none"}
                />
              </Button>
            )}
            {isDefault ? (
              <Button
                variant="ghost"
                size="sm"
                className="text-gray-600 hover:text-gray-900"
                onClick={() =>
                  navigate(`/playbooks/${playbook.id}/edit?isDefault=true`)
                }
              >
                <Eye className="h-3.5 w-3.5 mr-1.5" />
                View
              </Button>
            ) : (
              isEditable && (
                <>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="text-gray-600 hover:text-gray-900"
                    onClick={() => setShowShareModal(true)}
                  >
                    <Share className="h-3.5 w-3.5 mr-1.5" />
                    Share
                  </Button>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="text-gray-600 hover:text-gray-900"
                    onClick={() => navigate(`/playbooks/${playbook.id}/edit`)}
                  >
                    <Eye className="h-3.5 w-3.5 mr-1.5" />
                    View
                  </Button>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="text-red-500 hover:text-red-600"
                    onClick={() => setShowDeleteAlert(true)}
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </>
              )
            )}
          </div>
        </div>
        <div className="flex items-start gap-2 mt-2 flex-1">
          <span className="text-gray-500 text-justify">
            {playbook.description &&
              (playbook.description.length > descriptionCutoff
                ? `${playbook.description.slice(0, descriptionCutoff)}...`
                : playbook.description)}
          </span>
        </div>

        <div className="pt-4 mt-4 border-t">
          <Button
            variant="outline"
            className="w-full"
            onClick={() => setActivePlaybook(playbook, isDefault)}
          >
            Use Playbook
          </Button>
        </div>
      </Card>

      <AlertDialog open={showDeleteAlert} onOpenChange={setShowDeleteAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              playbook.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleDelete}>
              {isDeleting ? "Deleting..." : "Delete"}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {showShareModal && (
        <PlaybookShareModal
          open={showShareModal}
          onClose={() => setShowShareModal(false)}
          onShare={(email) => {
            sharePlaybook(playbook!.id, email);
          }}
          isSharing={isSharing}
          isFailed={isShareFailed}
        />
      )}
    </>
  );
}
