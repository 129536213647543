import { CompanyInfo } from "../components/company-info/models/CompanyInfo";
import { CompanyDocumentType } from "../enums/CompanyDocumentType";
import { CompanyDocumentView } from "./CompanyDocument";
import UploadItem from "./UploadItem";

export default class Context {
  companies: CompanyInfo[] = [];
  documents: CompanyDocumentView[] = [];
  uploadedItems: UploadItem[] = [];
  includeNews: boolean = true;
  includeWeb: boolean = true;
  selectedModel?: string;

  get isEmpty() {
    return (
      this.companies.length === 0 &&
      this.documents.length === 0 &&
      this.uploadedItems.length === 0
    );
  }

  public extractChatCreateData() {
    const companyIds = this.companies?.map((c: CompanyInfo) => c.id) || [];
    const filingIds =
      this.documents
        ?.filter(
          (d: CompanyDocumentView) => d.type === CompanyDocumentType.Filing,
        )
        .map((d: CompanyDocumentView) => d.id) || [];
    const transcriptIds =
      this.documents
        ?.filter(
          (d: CompanyDocumentView) => d.type === CompanyDocumentType.Transcript,
        )
        .map((d: CompanyDocumentView) => d.id) || [];
    const files =
      this.uploadedItems
        ?.filter((i: UploadItem) => i.type === "file" && i.file)
        ?.map((i: UploadItem) => i.file as File) || [];
    const urls =
      this.uploadedItems
        ?.filter((i: UploadItem) => i.type === "url" && i.url)
        ?.map((i: UploadItem) => i.url as string) || [];

    return {
      companyIds,
      transcriptIds,
      filingIds,
      files,
      urls,
      includeNews: this.includeNews,
      includeWeb: this.includeWeb,
      selectedModel: this.selectedModel,
    };
  }
}
