import { useAuth } from "../auth/useAuth";
import Axios, { AxiosHeaders } from "axios";
import { useState, useRef } from "react";
import { errorNotification } from "../utils/Notifications";
import { logger } from "../utils/Logger";
import { ApiResponse } from "../models/ApiResponse";
import qs from "qs";
import * as Sentry from "@sentry/react";
import axiosRetry from "axios-retry";

const axios = Axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
});
axiosRetry(axios, { retries: 3 });

const requestCache = new Map<string, Promise<any>>();

export const useApiPrivateRequest = <T>(mapper?: (response: any) => T) => {
  const [data, setData] = useState<T | null>(null);
  const [isFailed, setIsFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessToken, logout } = useAuth();
  const activeRequestsRef = useRef<Map<string, boolean>>(new Map());

  const call = async (
    url: string,
    method: "GET" | "POST" | "PUT" | "DELETE",
    params?: Record<string, unknown>,
    body?: any,
    headers?: AxiosHeaders,
  ) => {
    // Only generate a request key for GET requests since we only deduplicate those
    let requestKey: string | null = null;

    if (method === "GET") {
      // Generate a unique key for this GET request using qs.stringify for consistent key ordering
      requestKey = `${method}-${url}-${qs.stringify(params || {})}-${qs.stringify(body || {})}`;
    }

    try {
      // Only apply request deduplication for GET requests
      if (method === "GET" && requestKey && requestCache.has(requestKey)) {
        const cachedPromise = requestCache.get(requestKey);
        if (cachedPromise) {
          const response = await cachedPromise;

          // Ensure we're returning the data property of the response
          // to maintain consistency with the normal flow
          const result = response?.data?.data;

          if (mapper && result) {
            const mappedData = mapper(result);
            setData(mappedData);
          } else {
            setData(result);
          }
          return result;
        }
      }

      setData(null);
      setIsFailed(false);
      setIsLoading(true);

      // Mark this request as active for this hook instance if it's a GET request
      if (method === "GET" && requestKey) {
        activeRequestsRef.current.set(requestKey, true);
      }

      // Create a new request promise
      const requestPromise = axios.request<ApiResponse>({
        url: url,
        method: method,
        params: params,
        data: body,
        headers: {
          ...headers,
          Authorization: `Bearer ${await getAccessToken()}`,
        },
        validateStatus: (_) => true, // This is needed to prevent Axios from throwing an error on non-200 responses
        paramsSerializer: (params) => {
          return qs.stringify(params); // This is needed to stringify arrays properly
        },
      });

      // Only cache GET requests
      if (method === "GET" && requestKey) {
        requestCache.set(requestKey, requestPromise);
      }

      const response = await requestPromise;

      // Clean up cache and active requests tracking for GET requests
      if (method === "GET" && requestKey) {
        if (activeRequestsRef.current.get(requestKey)) {
          requestCache.delete(requestKey);
        }
        activeRequestsRef.current.delete(requestKey);
      }

      switch (response.status) {
        case 200:
          if (mapper) {
            const mappedData = mapper(response.data.data);
            setData(mappedData);
          } else {
            setData(response.data.data);
          }
          return response.data.data;
        case 401:
          logout();
          break;
        case 400: {
          const errors = response.data.data as string[];
          errors.forEach((error) => {
            errorNotification(response.data.message, error);
          });
          Sentry.captureMessage("Bad Request", {
            level: "error",
            tags: {
              source: "useApi",
              type: "bad_request",
              url: url,
              method: method,
            },
            extra: {
              errors: errors,
              message: response.data.message,
            },
          });
          setIsFailed(true);
          break;
        }
        case 404:
          errorNotification(
            "Resource requested was not found",
            response.data.message,
          );
          Sentry.captureMessage("Resource Not Found", {
            level: "error",
            tags: {
              source: "useApi",
              type: "not_found",
              url: url,
              method: method,
            },
            extra: {
              message: response.data.message,
            },
          });
          setIsFailed(true);
          break;
        case 403:
          errorNotification(
            "User is not authorized to perform this action",
            response.data.message,
          );
          Sentry.captureMessage("Forbidden", {
            level: "error",
            tags: {
              source: "useApi",
              type: "forbidden",
              url: url,
              method: method,
            },
            extra: {
              message: response.data.message,
            },
          });
          setIsFailed(true);
          break;
        default:
          errorNotification(
            "Unexpected server response",
            response.data.message,
          );
          Sentry.captureMessage(`Unexpected Response: ${response.status}`, {
            level: "error",
            tags: {
              source: "useApi",
              type: "unexpected_response",
              url: url,
              method: method,
              status: response.status.toString(),
            },
            extra: {
              message: response.data.message,
            },
          });
          setIsFailed(true);
          break;
      }
    } catch (error) {
      // Clean up cache and active requests tracking on error only for GET requests
      if (method === "GET" && requestKey) {
        if (activeRequestsRef.current.has(requestKey)) {
          requestCache.delete(requestKey);
        }
        activeRequestsRef.current.delete(requestKey);
      }

      errorNotification("No connection to the server");
      logger.error(error);
      Sentry.captureException(error, {
        tags: {
          source: "useApi",
          type: "server_connection_error",
        },
      });
      setIsFailed(true);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, setData, isFailed, isLoading, call };
};
