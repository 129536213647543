import { useEffect } from "react";
import { useGetChatFeatures } from "../api/useGetChatFeatures";
import { useSessionStorage } from "@mantine/hooks";
import { LocalStorageKeys } from "../enums/LocalStorageKeys";
import { ChatFeatures } from "../models/ChatFeatures";

export function useChatFeatures(isAuthenticated?: boolean) {
  const { features, getFeatures, isLoading } = useGetChatFeatures();
  const [savedFeatures, setSavedFeatures] = useSessionStorage<ChatFeatures>({
    key: LocalStorageKeys.ChatFeatures,
    defaultValue: undefined,
    // Need to set this to false to make sure it doesn't get the initial value undefined
    getInitialValueInEffect: false,
  });

  useEffect(() => {
    if (savedFeatures || isAuthenticated === false) return;
    getFeatures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedFeatures, isAuthenticated]);

  useEffect(() => {
    if (!features) return;
    setSavedFeatures(features);
  }, [features, setSavedFeatures]);

  return {
    features: savedFeatures,
    isLoading: !savedFeatures || isLoading,
  };
}
