export enum LocalStorageKeys {
  SidebarCompanyId = "SIDEBAR_COMPANY_ID",
  SidebarCitation = "SIDEBAR_CITATION",
  DownloadChatOnFinish = "DOWNLOAD_CHAT_ON_FINISH",
  RedirectUrl = "REDIRECT_URL",
  AddDocumentsTab = "ADD_DOCUMENTS_TAB",
  ExternalCompanyDoc = "EXTERNAL_COMPANY_DOC",
  ReportFormats = "REPORT_FORMATS",
  ChatFeatures = "CHAT_FEATURES",
}
