import { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Share, Mail } from "lucide-react";

interface PlaybookShareModalProps {
  open: boolean;
  onClose: () => void;
  onShare: (email: string) => void;
  isSharing?: boolean;
  isFailed?: boolean;
}

export function PlaybookShareModal({
  open,
  onClose,
  onShare,
  isSharing = false,
  isFailed = false,
}: PlaybookShareModalProps) {
  const [email, setEmail] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim());

  useEffect(() => {
    if (!isSharing && !isFailed && email !== "") {
      setIsSuccess(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSharing, isFailed]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setIsSuccess(false);
  };

  const handleShare = () => {
    if (isValid) {
      onShare(email.trim());
    }
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-lg">
        <DialogHeader>
          <div className="flex items-center gap-2">
            <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center">
              <Share className="h-4 w-4 text-primary" />
            </div>
            <DialogTitle className="text-xl">Share Playbook</DialogTitle>
          </div>
        </DialogHeader>

        <div className="space-y-6 py-4">
          <div className="space-y-2">
            <Label htmlFor="email">Email Address</Label>
            <div className="relative">
              <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-500" />
              <Input
                id="email"
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter recipient's email address"
                className="pl-10"
                disabled={isSharing}
              />
            </div>
            {isFailed && (
              <p className="text-sm text-red-500">
                Failed to share playbook. Please try again.
              </p>
            )}
            {isSuccess && (
              <p className="text-sm text-green-500">
                Playbook shared successfully!
              </p>
            )}
          </div>
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={onClose} disabled={isSharing}>
            Cancel
          </Button>
          <Button
            onClick={handleShare}
            disabled={!isValid || isSharing}
            className="min-w-[120px]"
          >
            {isSharing ? (
              <div className="flex items-center gap-2">
                <div className="w-4 h-4 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                Sharing...
              </div>
            ) : (
              "Share"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
