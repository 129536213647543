import { useApiPrivateRequest } from "@/shared/hooks/useApi";

export const useDeleteApiToken = () => {
  const {
    call,
    isLoading: isDeleting,
    isFailed: isDeleteFailed,
  } = useApiPrivateRequest<void>((response) => response);

  const deleteApiToken = async (userId: string) => {
    await call(`/admin/user/${userId}/apiToken`, "DELETE");
  };
  return { deleteApiToken, isDeleting, isDeleteFailed };
};
