import { ChatMode } from "../../../shared/enums/ChatMode";
import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { ChatShort } from "../../../shared/models/Chat";

export interface GetChatProps {
  chatModes?: ChatMode[];
  page: number;
  pageSize?: number;
  companyId?: number;
  query?: string;
  from?: Date;
  to?: Date;
}

export const useGetChats = (userId?: string) => {
  const {
    data: chats,
    call,
    isLoading,
  } = useApiPrivateRequest<ChatShort[]>((response) =>
    response.map((chat: any) => new ChatShort(chat)),
  );

  const getChats = async (props: GetChatProps) => {
    const result = await call(`${userId ? "/admin" : ""}/chat`, "GET", {
      userId,
      ...props,
    });

    if (!result) {
      return [];
    }

    const mappedChats = result.map((chat: any) => new ChatShort(chat));
    return mappedChats;
  };

  return { chats, getChats, isLoading };
};
