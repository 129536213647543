import { createContext } from "react";
import Keycloak from "keycloak-js";

// Simple context definition without any initialization
export const KeycloakContext = createContext<{
  keycloak: Keycloak;
  initialized: boolean;
}>({
  keycloak: {} as Keycloak,
  initialized: false,
});
