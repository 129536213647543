import { Feedback } from "../../features/existing-chat/models/Feedback";
import { ChatMode } from "../enums/ChatMode";
import { ChatMessage } from "../../features/existing-chat/models/ChatMessage";
import { ChatUserContent } from "../../features/existing-chat/models/ChatUserContent";
import { ChatReport } from "./ChatReport";
import { ChatMetadata } from "./ChatMetadata";

export class ChatShort {
  public id: string;
  public input: string;
  public playbookId: string | null;
  public playbookTitle: string | null;
  public startedAt: Date;
  public finishedAt?: Date;
  public mode: ChatMode;
  public suggestedQuestions?: string[];
  public settings: Record<string, string>;
  public metadata: ChatMetadata;

  constructor(response: any) {
    this.id = response.id as string;
    this.input = response.input as string;
    this.playbookId = response.playbookId as string;
    this.playbookTitle = response.playbookTitle as string;
    this.startedAt = new Date(response.startedAt);
    if (response.finishedAt) {
      this.finishedAt = new Date(response.finishedAt);
    }
    this.mode = response.mode as ChatMode;
    this.suggestedQuestions = response.suggestedQuestions;
    this.settings = response.settings;
    this.metadata = new ChatMetadata(response.metadata);
  }

  public get inProgress() {
    return !this.finishedAt;
  }

  public get timeInterval() {
    //plus operator converts Date to number
    return this.finishedAt ? +this.finishedAt - +this.startedAt : undefined;
  }
}

export class Chat extends ChatShort {
  public feedback?: Feedback;
  public messages: ChatMessage[];
  public report?: ChatReport;
  public userContent: ChatUserContent[];

  constructor(response: any) {
    super(response);
    this.messages = response.messages.map(
      (message: any) => new ChatMessage(message, response.messages),
    );
    if (response.feedback) {
      this.feedback = new Feedback(response.feedback);
    }
    this.report = response.report ? new ChatReport(response.report) : undefined;
    this.userContent = (response.userContent ?? []).map(
      (data: any) => new ChatUserContent(data),
    );
  }
}
