import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { GripVertical, Wand2, X } from "lucide-react";
import { PlaybookStepShort } from "../model/Playbook";
import { useToast } from "@/hooks/use-toast";
import { useState } from "react";
import { PromptImproverModal } from "../../existing-chat/components/PromptImproverModal";

interface SortableStepProps {
  playbookId: string;
  step: PlaybookStepShort;
  index: number;
  editMode: boolean;
  isReadOnly?: boolean;
  onStepChange: (
    id: string,
    field: keyof PlaybookStepShort,
    value: string,
  ) => void;
  onDeleteStep: (id: string) => void;
  onSave: (currentStep?: PlaybookStepShort) => Promise<void>;
}

export function SortableStep({
  playbookId,
  step,
  index,
  editMode,
  isReadOnly = false,
  onStepChange,
  onDeleteStep,
  onSave,
}: SortableStepProps) {
  const { toast } = useToast();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: step.id });
  const [showImproveModal, setShowImproveModal] = useState(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleAcceptImprovement = async (improvedPrompt: string) => {
    // Create new step with the improved prompt
    const updatedStep = { ...step, prompt: improvedPrompt };
    onStepChange(step.id, "prompt", improvedPrompt);
    try {
      await onSave(updatedStep);
      toast({
        title: "Success",
        description: "Successfully updated the prompt.",
      });
      setShowImproveModal(false);
    } catch {
      toast({
        title: "Error",
        description: "Failed to save the improved prompt.",
        variant: "destructive",
      });
    }
  };

  return (
    <>
      <div ref={setNodeRef} style={style} className="mb-3">
        <Card className="border-gray-200 transition-shadow hover:shadow-md">
          <CardContent className="p-3">
            <div className="flex items-start gap-3">
              {editMode && (
                <div
                  className="mt-1 cursor-move"
                  {...attributes}
                  {...listeners}
                >
                  <GripVertical className="h-4 w-4 text-gray-400" />
                </div>
              )}

              <div className="flex-1">
                <div className="flex items-center gap-3 mb-2">
                  <span className="flex items-center justify-center w-6 h-6 rounded-full bg-gray-100 text-sm font-medium">
                    {index + 1}
                  </span>
                  {editMode ? (
                    <Input
                      value={step.title}
                      onChange={(e) =>
                        onStepChange(step.id, "title", e.target.value)
                      }
                      className="font-medium"
                      placeholder="Step title"
                    />
                  ) : (
                    <h3 className="font-medium text-lg">{step.title}</h3>
                  )}
                </div>

                <div className="pl-9 space-y-2">
                  {editMode ? (
                    <div className="space-y-2">
                      <div>
                        <div className="flex items-center justify-between mb-1">
                          <label className="text-sm font-medium text-gray-700">
                            Research Prompt
                          </label>
                        </div>
                        <Textarea
                          value={step.prompt}
                          onChange={(e) =>
                            onStepChange(step.id, "prompt", e.target.value)
                          }
                          placeholder="Enter the research prompt for this step"
                          className="h-24"
                          rows={3}
                        />
                      </div>
                      <div className="flex justify-end">
                        <Button
                          type="button"
                          variant="ghost"
                          size="sm"
                          onClick={() => onDeleteStep(step.id)}
                          className="text-red-600 hover:text-red-700"
                        >
                          <X className="h-3.5 w-3.5 mr-1.5" />
                          Delete
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="space-y-2">
                      <div>
                        <div className="flex items-center justify-between mb-2">
                          <h4 className="text-sm font-medium text-gray-700">
                            Research Prompt
                          </h4>
                          {!editMode && !isReadOnly && (
                            <Button
                              type="button"
                              variant="ghost"
                              size="sm"
                              onClick={(e) => {
                                e.preventDefault();
                                setShowImproveModal(true);
                              }}
                              className="text-gray-600 hover:text-gray-900"
                            >
                              <Wand2 className="h-3.5 w-3.5 mr-1.5" />
                              Improve
                            </Button>
                          )}
                        </div>
                        <div className="bg-gray-50 p-4 rounded-md text-sm border border-gray-100 whitespace-pre-wrap">
                          {step.prompt}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>

      <PromptImproverModal
        isOpen={showImproveModal}
        onClose={() => {
          setShowImproveModal(false);
        }}
        originalPrompt={step.prompt}
        onAccept={handleAcceptImprovement}
        playbookId={playbookId}
        stepId={step.id}
      />
    </>
  );
}
