import { useState, useEffect, useMemo } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { X, FileText, Loader2, Plus, Search, Building2 } from "lucide-react";
import { useSearchCompanies } from "@/shared/hooks/useSearchCompanies";
import { useSearchCompanyDocs } from "@/shared/api/useSearchCompanyDocs";
import { CompanyInfo } from "@/shared/components/company-info/models/CompanyInfo";
import { CompanyDocumentView } from "@/shared/models/CompanyDocument";
import { useGetCompanyYears } from "../api/useGetCompanyYears";

interface DocumentSearchOverlayProps {
  isOpen: boolean;
  onClose: () => void;
  onDocumentSelect: (document: CompanyDocumentView) => void;
  onDocumentRemove: (documentId: string) => void;
  selectedDocuments: CompanyDocumentView[];
}

export function DocumentSearchOverlay({
  isOpen,
  onClose,
  onDocumentSelect,
  onDocumentRemove,
  selectedDocuments,
}: DocumentSearchOverlayProps) {
  // Company search state
  const [companySearchQuery, setCompanySearchQuery] = useState("");
  const {
    companies,
    searchCompanies,
    isLoading: isLoadingCompanies,
    setCompanies,
  } = useSearchCompanies();
  const [selectedCompany, setSelectedCompany] = useState<CompanyInfo | null>(
    null,
  );
  const { years, getCompanyYears } = useGetCompanyYears();

  // Document search state
  const {
    results: documents,
    searchCompanyDocs,
    isSearching: isLoadingDocs,
  } = useSearchCompanyDocs();
  const [documentFilter, setDocumentFilter] = useState({
    type: "",
    year: "",
  });

  // Filter documents based on type
  const filteredDocuments = useMemo(() => {
    if (!documents || !documentFilter.type) return documents;

    return documents.filter((doc) => {
      if (documentFilter.type === "EARNINGS_CALL") {
        // Show only earnings call transcripts
        return (
          doc.type === "Transcript" &&
          (doc.title.toLowerCase().includes("earnings") ||
            doc.formType.toLowerCase().includes("earnings"))
        );
      }

      if (documentFilter.type === "OTHER") {
        // For filings: exclude 10-K, 10-Q, and 8-K
        if (doc.type === "Filing") {
          return !["10-K", "10-Q", "8-K"].includes(doc.formType);
        }
        // For transcripts: exclude earnings calls
        if (doc.type === "Transcript") {
          return !(
            doc.title.toLowerCase().includes("earnings") ||
            doc.formType.toLowerCase().includes("earnings")
          );
        }
        return false;
      }

      return doc.formType === documentFilter.type;
    });
  }, [documents, documentFilter.type]);

  // Show selected documents panel state
  const [showSelectedDocuments, setShowSelectedDocuments] = useState(false);

  // Handle company search input
  useEffect(() => {
    if (!companySearchQuery) {
      setCompanies(null);
      return;
    }

    const timer = setTimeout(() => {
      searchCompanies(companySearchQuery);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySearchQuery]);

  // Handle company selection
  useEffect(() => {
    if (selectedCompany) {
      getCompanyYears(selectedCompany.id);
      searchCompanyDocs(
        selectedCompany.id,
        undefined, // page
        undefined, // pageSize
        documentFilter.year || undefined,
        documentFilter.year || undefined, // Use same year for start and end if specified
        undefined, // Don't send type to backend, we'll filter on frontend
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany, documentFilter.year]);

  // Reset state when modal closes
  useEffect(() => {
    if (!isOpen) {
      setCompanySearchQuery("");
      setSelectedCompany(null);
      setCompanies(null);
      setDocumentFilter({
        type: "",
        year: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleYearChange = (year: string) => {
    setDocumentFilter((prev) => ({ ...prev, year }));
  };

  const handleTypeChange = (type: string) => {
    setDocumentFilter((prev) => ({ ...prev, type }));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      {!showSelectedDocuments ? (
        <div className="bg-white rounded-xl shadow-2xl w-[800px] h-[80vh] flex flex-col relative">
          {/* Header with Filters */}
          <div className="p-6 border-b">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-xl font-semibold text-gray-900">
                Add Specific Documents
              </h3>
              <Button
                variant="ghost"
                size="icon"
                onClick={onClose}
                className="hover:bg-gray-100"
              >
                <X className="h-4 w-4" />
              </Button>
            </div>

            {/* Company Search */}
            <div className="mb-4">
              {!selectedCompany ? (
                <>
                  <div className="flex items-center gap-2 bg-gray-50 rounded-lg p-2">
                    <Search className="h-5 w-5 text-gray-400" />
                    <Input
                      placeholder="Search by company name or ticker..."
                      value={companySearchQuery}
                      onChange={(e) => setCompanySearchQuery(e.target.value)}
                      className="flex-1 border-0 bg-transparent focus-visible:ring-0"
                      autoFocus
                    />
                    {isLoadingCompanies && (
                      <Loader2 className="h-4 w-4 animate-spin text-gray-400" />
                    )}
                  </div>
                  {companySearchQuery.trim() &&
                    companies &&
                    companies.length > 0 && (
                      <div className="mt-2">
                        <div className="text-sm font-medium text-gray-900 mb-2">
                          Search Results
                        </div>
                        <div className="space-y-1">
                          {companies?.map((company) => (
                            <button
                              key={company.id}
                              onClick={() => {
                                setSelectedCompany(company);
                                setCompanySearchQuery("");
                                setCompanies(null);
                              }}
                              className="w-full text-left p-3 hover:bg-gray-50 rounded-lg flex items-center justify-between group transition-colors"
                            >
                              <div className="flex items-center gap-3">
                                <Building2 className="h-5 w-5 text-gray-400" />
                                <span className="font-medium">
                                  {company.companyName}
                                </span>
                              </div>
                              <div className="flex items-center gap-2">
                                {company.tickerSymbol && (
                                  <span className="text-gray-500">
                                    {company.tickerSymbol}
                                  </span>
                                )}
                                <Plus className="h-4 w-4 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" />
                              </div>
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                </>
              ) : (
                <div className="flex items-center justify-between bg-blue-50 text-blue-700 px-3 py-2 rounded-lg">
                  <div className="flex items-center gap-2">
                    <Building2 className="h-4 w-4" />
                    <span className="font-medium">
                      {selectedCompany.companyName}
                    </span>
                    {selectedCompany.tickerSymbol && (
                      <span className="text-sm text-blue-500">
                        ({selectedCompany.tickerSymbol})
                      </span>
                    )}
                  </div>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => {
                      setSelectedCompany(null);
                      setCompanySearchQuery("");
                      setCompanies(null);
                    }}
                    className="text-blue-700 hover:text-blue-900 hover:bg-blue-100"
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </div>
              )}
            </div>

            {/* Filters */}
            {selectedCompany && (
              <div className="grid grid-cols-2 gap-4">
                <select
                  className="w-full rounded-lg border border-gray-200 p-2"
                  value={documentFilter.type}
                  onChange={(e) => handleTypeChange(e.target.value)}
                >
                  <option value="">All Types</option>
                  <option value="10-K">10-K</option>
                  <option value="10-Q">10-Q</option>
                  <option value="8-K">8-K</option>
                  <option value="EARNINGS_CALL">
                    Earnings Call Transcript
                  </option>
                  <option value="OTHER">Other</option>
                </select>

                <select
                  className="w-full rounded-lg border border-gray-200 p-2"
                  value={documentFilter.year}
                  onChange={(e) => handleYearChange(e.target.value)}
                >
                  <option value="">All Years</option>
                  {years &&
                    years.map((year) => (
                      <option key={year} value={year.toString()}>
                        {year}
                      </option>
                    ))}
                </select>
              </div>
            )}
          </div>

          {/* Document List */}
          <div className="flex-1 p-6 overflow-y-auto min-h-0">
            <div className="space-y-2">
              {selectedCompany ? (
                isLoadingDocs ? (
                  <div className="text-center py-4">
                    <Loader2 className="h-6 w-6 animate-spin mx-auto text-gray-400" />
                  </div>
                ) : filteredDocuments?.length ? (
                  filteredDocuments.map((doc) => {
                    const isSelected = selectedDocuments.some(
                      (d) => d.id === doc.id,
                    );
                    return (
                      <button
                        key={doc.id}
                        onClick={() => {
                          if (isSelected) {
                            onDocumentRemove(doc.id);
                          } else {
                            onDocumentSelect(doc);
                          }
                        }}
                        className={`w-full text-left p-4 rounded-lg flex items-center justify-between group transition-colors
                                  ${
                                    isSelected
                                      ? "bg-emerald-50 hover:bg-emerald-100"
                                      : "hover:bg-gray-50"
                                  }`}
                      >
                        <div className="flex items-center gap-3">
                          <FileText
                            className={`h-5 w-5 ${isSelected ? "text-emerald-500" : "text-gray-400"}`}
                          />
                          <div>
                            <div
                              className={`font-medium ${isSelected ? "text-emerald-900" : "text-gray-900"}`}
                            >
                              {doc.title}
                            </div>
                            <div
                              className={`text-sm ${isSelected ? "text-emerald-600" : "text-gray-500"}`}
                            >
                              {doc.companyName} • {doc.type} •{" "}
                              {doc.filedAt?.toLocaleDateString()}
                            </div>
                          </div>
                        </div>
                        {isSelected ? (
                          <div className="text-emerald-500 text-sm font-medium">
                            Selected
                          </div>
                        ) : (
                          <Plus className="h-4 w-4 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" />
                        )}
                      </button>
                    );
                  })
                ) : (
                  <div className="text-center text-gray-500 py-8">
                    <FileText className="h-12 w-12 mx-auto mb-3 text-gray-400" />
                    <p>No documents found matching your filters</p>
                  </div>
                )
              ) : (
                <div className="text-center text-gray-500 py-8">
                  <FileText className="h-12 w-12 mx-auto mb-3 text-gray-400" />
                  <p>Search and select a company to view documents</p>
                </div>
              )}
            </div>
          </div>

          {/* Footer */}
          <div className="p-6 border-t bg-gray-50 flex items-center justify-between">
            <button
              onClick={() => setShowSelectedDocuments(true)}
              className="text-sm text-gray-600 hover:text-gray-900"
            >
              {selectedDocuments.length} documents selected
            </button>
            <Button onClick={onClose}>Done</Button>
          </div>
        </div>
      ) : (
        <div className="bg-white rounded-xl shadow-2xl w-[800px] h-[80vh] flex flex-col">
          {/* Selected Documents View */}
          <div className="p-6 border-b">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-xl font-semibold text-gray-900">
                Selected Documents
              </h3>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setShowSelectedDocuments(false)}
                className="hover:bg-gray-100"
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
          </div>

          {/* Selected Documents List */}
          <div className="flex-1 p-6 overflow-y-auto">
            <div className="space-y-2">
              {selectedDocuments.map((doc) => (
                <div
                  key={doc.id}
                  className="flex items-center justify-between bg-emerald-50 text-emerald-700 px-4 py-3 rounded-lg"
                >
                  <div className="flex items-center gap-3">
                    <FileText className="h-5 w-5" />
                    <div>
                      <div className="font-medium">{doc.title}</div>
                      <div className="text-sm text-emerald-600">
                        {doc.companyName} • {doc.type} •{" "}
                        {doc.filedAt?.toLocaleDateString()}
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => onDocumentRemove(doc.id)}
                    className="text-emerald-500 hover:text-emerald-700"
                  >
                    <X className="h-4 w-4" />
                  </button>
                </div>
              ))}
            </div>
          </div>

          {/* Footer */}
          <div className="p-6 border-t bg-gray-50 flex items-center justify-between">
            <div className="text-sm text-gray-600">
              {selectedDocuments.length} documents selected
            </div>
            <div className="flex gap-3">
              <Button
                variant="outline"
                onClick={() => setShowSelectedDocuments(false)}
              >
                Back to Search
              </Button>
              <Button onClick={onClose}>Done</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
