import { useApiPrivateRequest } from "@/shared/hooks/useApi";

export const useCheckIfApiTokenExists = () => {
  const {
    data: tokenExists,
    call,
    isLoading: isChecking,
    setData: setTokenExists,
  } = useApiPrivateRequest<boolean>((response) => response);

  const checkIfApiTokenExists = async (userId: string) => {
    await call(`/admin/user/${userId}/apiToken`, "GET");
  };

  return { tokenExists, checkIfApiTokenExists, isChecking, setTokenExists };
};
