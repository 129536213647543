import { CitationIndex } from "../../../sources/models/Citation";
import { useEffect, useMemo } from "react";
import { useGetWebResult } from "../../../sources/api/useGetWebResult";
import CenterLoader from "../../../../shared/components/loader/CenterLoader";
import SidebarWebLink from "./SidebarWebLink";
import { extractDomain } from "../../../../shared/utils/Urls";
import DOMPurify from "dompurify";
import { CitationSourceType } from "../../../../shared/enums/CitationSourceType";
import { ScrollArea } from "@/components/ui/scroll-area";

interface SidebarWebCitationProps {
  citation: CitationIndex;
  chatId: string;
}

export function SidebarWebCitation({
  citation,
  chatId,
}: SidebarWebCitationProps) {
  const { webResult, getWebResult, isLoading } = useGetWebResult();
  const memoizedCitation = useMemo(() => citation, [citation]);

  useEffect(() => {
    getWebResult(chatId, memoizedCitation.documentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedCitation]);

  return (
    <>
      {isLoading && <CenterLoader />}
      {webResult && (
        <>
          <SidebarWebLink
            title={webResult.title}
            url={webResult.url}
            source={extractDomain(webResult.url)}
            sourceType={CitationSourceType.Internet}
          />
          <ScrollArea className="mt-5">
            <p
              className="text-sm leading-relaxed"
              style={{ overflowWrap: "anywhere" }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(webResult.description),
              }}
            />
          </ScrollArea>
        </>
      )}
    </>
  );
}
