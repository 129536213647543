import { useApiPrivateRequest } from "../hooks/useApi";

export interface MlModelInfo {
  mlModel: string;
  label: string;
  description: string;
  speed: number;
  intelligence: number;
}

export interface PrimaryGenericMlModelInfo {
  mlModelInfos: MlModelInfo[];
  selectedMlModel: string;
}

export const useGetPrimaryGenericMlModelsInfo = () => {
  const {
    data: mlModelsInfo,
    call,
    isLoading,
  } = useApiPrivateRequest<PrimaryGenericMlModelInfo>();

  const getMlModelsInfo = async () => {
    await call("/chat/primaryGenericMlModelsInfo", "GET");
  };

  return { mlModelsInfo, getMlModelsInfo, isLoading };
};
