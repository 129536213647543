import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useCreateApiToken } from "@/features/admin-users-list/api/useCreateApiToken";
import { Button } from "@/components/ui/button";
import { useClipboard } from "@mantine/hooks";
import { Loader2 } from "lucide-react";
import { useCheckIfApiTokenExists } from "@/features/admin-users-list/api/useCheckIfApiTokenExists";
import { useDeleteApiToken } from "@/features/admin-users-list/api/useDeleteApiToken";
import { useEffect } from "react";
import CenterLoader from "@/shared/components/loader/CenterLoader";

interface CreateApiTokenModalProps {
  opened: boolean;
  close: () => void;
  userId: string;
  email: string;
}

export default function CreateApiTokenModal({
  opened,
  close,
  userId,
  email,
}: CreateApiTokenModalProps) {
  const { tokenExists, checkIfApiTokenExists, isChecking, setTokenExists } =
    useCheckIfApiTokenExists();
  const { deleteApiToken, isDeleting, isDeleteFailed } = useDeleteApiToken();
  const {
    token,
    createApiToken,
    isLoading: isTokenLoading,
    setToken,
    isCreateTokenFailed,
  } = useCreateApiToken();
  const clipboard = useClipboard({ timeout: 2000 });

  useEffect(() => {
    if (opened) {
      checkIfApiTokenExists(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened, userId]);

  const handleCreateClick = async () => {
    await createApiToken(userId);
    if (!isCreateTokenFailed) setTokenExists(true);
  };

  const handleDelete = async () => {
    await deleteApiToken(userId);
    if (!isDeleteFailed) {
      setToken(null);
      setTokenExists(false);
    }
  };

  const handleClose = () => {
    close();
    setToken(null);
  };

  return (
    <Dialog open={opened} onOpenChange={(open) => !open && handleClose()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="pb-3">
            {email} {tokenExists ? "has a token" : "doesn't have a token"}
          </DialogTitle>
        </DialogHeader>
        <div>
          {isChecking ? (
            <CenterLoader size={"sm"} />
          ) : (
            <>
              {token && <span>API token: {token}</span>}
              <div className="flex justify-center pt-3 gap-4">
                {!tokenExists && !token && (
                  <Button
                    disabled={isChecking || isTokenLoading || isDeleting}
                    onClick={handleCreateClick}
                  >
                    {isTokenLoading ? (
                      <>
                        <Loader2 className="h-4 w-4 animate-spin mr-2" />
                        Creating...
                      </>
                    ) : (
                      "Create token"
                    )}
                  </Button>
                )}
                {(tokenExists || token) && (
                  <Button
                    disabled={isChecking || isTokenLoading || isDeleting}
                    onClick={() => handleDelete()}
                  >
                    {isDeleting ? (
                      <>
                        <Loader2 className="h-4 w-4 animate-spin mr-2" />
                        Revoking...
                      </>
                    ) : (
                      "Revoke token"
                    )}
                  </Button>
                )}
                {token && (
                  <Button onClick={() => clipboard.copy(token)}>
                    {clipboard.copied
                      ? "Token copied"
                      : "Copy token to clipboard"}
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
