import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import ChatContext from "@/shared/models/ChatContext";
import { useEstimatePlaybookChat } from "../api/useEstimatePlaybookChat";
import { useEffect } from "react";
import CenterLoader from "@/shared/components/loader/CenterLoader";

interface PlaybookEstimateModalProps {
  open: boolean;
  onClose: () => void;
  playbookId: string;
  isDefault: boolean;
  context: ChatContext;
  onComplete: () => void;
}

export default function PlaybookEstimateModal({
  open,
  onClose,
  playbookId,
  isDefault,
  context,
  onComplete,
}: PlaybookEstimateModalProps) {
  const { cost, estimate, isLoading, isFailed } = useEstimatePlaybookChat();

  useEffect(() => {
    estimate(playbookId, isDefault, context);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleComplete = () => {
    onComplete();
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-lg">
        <DialogHeader>
          <DialogTitle className="text-xl">Cost Estimate</DialogTitle>
          <p className="text-sm text-gray-500">
            Estimated cost to run playbook
          </p>
        </DialogHeader>

        <div className="space-y-6 py-4 text-center">
          {cost && (
            <>
              <div className="text-3xl font-bold">
                ${cost ? cost.toFixed(2) : "-"}
              </div>
              <p className="text-sm text-gray-500">
                This is an estimated cost based on the selected context. Actual
                cost may vary slightly.
              </p>
            </>
          )}
          {isLoading && <CenterLoader size="sm" />}
          {isFailed && (
            <div className="flex items-center space-x-2">
              <p className="text-sm text-red-500">Failed to estimate cost</p>
            </div>
          )}
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="min-w-[120px]"
            disabled={!cost || isFailed}
            onClick={handleComplete}
          >
            Run
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
