import { useGetCompanyDocumentSource } from "../../../sources/api/useGetCompanyDocumentSource";
import { CitationIndex } from "../../../sources/models/Citation";
import { useEffect, useMemo } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { parseIntOrUndefined } from "../../../../shared/utils/Number";
import CenterLoader from "../../../../shared/components/loader/CenterLoader";
import SidebarCompanyDocumentLink from "./SidebarCompanyDocumentLink";

interface SidebarDocumentCitationProps {
  citation: CitationIndex;
}

export function SidebarDocumentCitation({
  citation,
}: SidebarDocumentCitationProps) {
  const { source, getCompanyDocumentSource, isLoading } =
    useGetCompanyDocumentSource();
  const memoizedCitation = useMemo(() => citation, [citation]);

  useEffect(() => {
    getCompanyDocumentSource(
      memoizedCitation.sourceType,
      memoizedCitation.documentId,
      parseIntOrUndefined(memoizedCitation.chunkId),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedCitation]);

  return (
    <>
      {isLoading && <CenterLoader />}
      <div className="flex">
        {source && (
          <SidebarCompanyDocumentLink
            document={source}
            chunkId={parseIntOrUndefined(memoizedCitation.chunkId)}
          />
        )}
      </div>
      {source && (
        <ScrollArea className="mt-5">
          <p
            className="text-sm leading-relaxed"
            style={{ overflowWrap: "anywhere" }}
          >
            {source.content}
          </p>
        </ScrollArea>
      )}
    </>
  );
}
