import ChatContext from "@/shared/models/ChatContext";
import { useApiPrivateRequest } from "@/shared/hooks/useApi";

interface ImprovePromptBeforeChatRequest {
  prompt: string;
  context: any;
  improvementDescription?: string;
}

export const useImprovePromptBeforeChat = () => {
  const {
    data: improvedPrompt,
    call,
    isLoading,
  } = useApiPrivateRequest<string>((response) => response);

  const improvePromptBeforeChat = async (
    prompt: string,
    chatContext: ChatContext,
    improvementDescription?: string,
  ) => {
    const data = chatContext.extractChatCreateData();
    const request: ImprovePromptBeforeChatRequest = {
      prompt,
      improvementDescription,
      context: {
        companyIds: data.companyIds,
        filingIds: data.filingIds,
        transcriptIds: data.transcriptIds,
        urls: data.urls,
        files: data.files.map((file) => file.name),
      },
    };

    return await call(
      "/generation/improvePromptBeforeChat",
      "POST",
      undefined,
      request,
    );
  };

  return { improvedPrompt, improvePromptBeforeChat, isLoading };
};
